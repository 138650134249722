import React, { useState } from "react";
import { useAppContext } from "../../context/appContext/app-context";
import { resetToast } from "../../context/appContext/app-context.actions";
import bookImage from "../../assets/KathaiIcons/learn_pink.png";

const InformationToast = ({ level }) => {
  const {
    appState: { toastInformation: toast },
    appDispatch,
  } = useAppContext();

  const dismissToast = () => {
    localStorage.setItem(`level${level}`, false);
    localStorage.setItem(`level${level}_permanent_disable`, true);
    appDispatch(resetToast());
  };
  const seeLaterToast = () => {
    localStorage.setItem(`level${level}`, false);
    setTimeout(() => {
      localStorage.setItem(`level${level}`, true);
    }, 1000 * 60 * 5);
    appDispatch(resetToast());
  };

  console.log("watchURL", toast);

  return (
    toast.isVisible && (
      <>
        <div className="flex flex-col items-center  overflow-x-hidden overflow-y-auto fixed z-50 outline-none focus:outline-none bottom-8 right-0 left-40">
          <div className="flex flex-row justify-between items-start bg-white text-black w-4/5 border-2 border-primary-500 ">
            <img className="self-start w-20" src={bookImage} alt="explain" />
            <div className=" rounded-lg flex flex-col justify-center items-start px-8 py-2 drop-shadow-md filter flex-grow">
              <div className="font-semibold text-primary-500">
                {toast.title}
              </div>
              <div className="overflow-y-auto">{toast.description}</div>
              <div
                className="flex flex-row w-full underline items-center mt-10"
                style={{ textDecorationColor: "#de576a90" }}
              >
                <div
                  onClick={() => window.open(toast.watchURL)}
                  className="w-1/2 justify-self-start cursor-pointer text-primary-500"
                >
                  Watch How
                </div>
                <div className="flex flex-row w-1/2 justify-self-end justify-end items-center">
                  <div
                    className="mx-2 cursor-pointer text-primary-500"
                    onClick={dismissToast}
                  >
                    Dismiss
                  </div>
                  <div
                    className="mx-2 cursor-pointer text-primary-500"
                    onClick={seeLaterToast}
                  >
                    Later
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default InformationToast;
