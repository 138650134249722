import firebase from "firebase/app";

//sample UserInfo Doc.
const UserInfo = {
  name: "salman",
  email: "salmanul33@hotmail.com",
  userId: "",
  more: {},
};

export const createUser = async (uid, userInfo) => {
  if (uid) {
    const userRef = firebase.firestore().collection("users").doc(uid);
    try {
      const snapshotDoc = await userRef.get(); // check if the user already exist
      if (!snapshotDoc.exists) {
        // save userinfo
        await userRef.set({
          ...userInfo,
        });
      } else {
        console.error("UserInfo already exist");
      }
    } catch (e) {
        console.error('Error while saving in db');
    }
  }
};
