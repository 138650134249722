import firebase from "firebase/app";
import { createUser } from "../services/user";

export const signInWithGoogle = async () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  const result = await firebase.auth().signInWithPopup(provider);
  // console.log(result);

  const uid = result.user.uid;
  if (result.additionalUserInfo.isNewUser) {
    // create newuserinfo and add into users collection
    const profile = result.additionalUserInfo.profile;
    const userInfo = {
      name: profile.name,
      profilepicture: profile.picture,
      email: profile.email,
      userId: uid,
      more: null,
    };
    await createUser(uid, userInfo);
  }
  return result;
};
