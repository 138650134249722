import React, { useState, useRef, useEffect } from "react";
import * as XLSX from "xlsx";
import DataTable from "react-data-table-component";
import { exportPDF, exportImage, exportZIP } from "../services/storage/index";
const ExportConfigModal = ({ exportModal, setExportModal, emailEditorRef }) => {
  // setExportModal can be used to close this Modal
  const showModal = !!exportModal;
  const [isExporting, setIsExport] = useState(false);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);

  // process CSV data
  const processData = (dataString) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );
    // headers.push("Export");

    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      );
      if (headers && row.length == headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] == '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] == '"') d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
          if (headers[headers.length - 1]) {
            obj[headers[j]] = "PDF";
          }
        }

        // remove the blank rows
        if (Object.values(obj).filter((x) => x).length > 0) {
          list.push(obj);
        }
      }
    }

    // prepare columns list from headers
    const columns = headers.map((c) => ({
      name: c,
      selector: c,
    }));

    console.log("columns", columns);
    // columns.push({
    //   name: "Export",
    //   selector: "Export",
    // })
    columns.push({
      name: "Export",
      selector: "Export",

      cell: (row) => {
        return (
          <div data-tag="allowRowEvents">
            <button
              className="border-black border-2 rounded-md cursor-pointer flex justify-center bg-gray-800 text-gray-200 hover:bg-gray-900 hover:text-gray-100 mt-2"
              // onClick={generatePDF}
              onClick={(e) => {
                console.log("generate pdf for row", row);
                generatePDF(row);
              }}
              disabled={isExporting}
            >
              Export PDF
            </button>
            <button
              className="border-black border-2 rounded-md cursor-pointer flex justify-center bg-gray-800 text-gray-200 hover:bg-gray-900 hover:text-gray-100 mt-2"
              // onClick={generatePDF}
              onClick={(e) => {
                console.log("generate zip for row", row);
                generateZIP(row);
              }}
              disabled={isExporting}
            >
              Export ZIP
            </button>
          </div>
        );
      },
    });

    setData(list);
    setColumns(columns);
  };

  // handle file upload
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      processData(data);
    };
    reader.readAsBinaryString(file);
  };

  const generatePDF = (mergeTags) => {
    emailEditorRef?.current?.editor?.exportHtml(async (data) => {
      setIsExport(true);
      const { design, html } = data;
      var pdfURL = await exportPDF(design, mergeTags);
      console.log("pdfURL", pdfURL);
      const link = document.createElement("a");
      link.href = pdfURL;
      link.target = "_blank"; // Open in new tab.
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setIsExport(false);
    });
  };
  const generateZIP = (mergeTags) => {
    emailEditorRef?.current?.editor?.exportHtml(async (data) => {
      setIsExport(true);
      const { design, html } = data;
      var zipURL = await exportZIP(design, mergeTags);
      console.log("zipURL", zipURL);
      const link = document.createElement("a");
      link.href = zipURL;
      link.target = "_blank"; // Open in new tab.
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setIsExport(false);
    });
  };
  return (
    <div>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div
              className="relative w-auto my-6 mx-auto"
              style={{ maxWidth: "90%" }}
            >
              {/*content*/}
              <div
                className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
                style={{
                  backgroundColor: "#f2f2f2",
                  minHeight: "65vh",
                  minWidth: "60vw",
                  maxHeight: "90vh",
                }}
              >
                {/*header*/}
                <div className="flex items-start justify-between p-5 rounded-t">
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => {
                      setData([]);
                      setColumns([]);
                      setExportModal(false);
                    }}
                  >
                    <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none opacity-90">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto overflow-auto max-h-full">
                  <div className="flex w-full h-full">
                    <div className="flex-1 h-full w-full">
                      <h1 className="p-2 text-3xl pb-4">Export with Data</h1>
                      <input
                        className="border-black border-2 rounded-md p-2 cursor-pointer flex justify-center bg-gray-800 text-gray-200 hover:bg-gray-900 hover:text-gray-100 mt-2"
                        type="file"
                        accept=".csv,.xlsx,.xls"
                        onChange={handleFileUpload}
                      />
                      <p className="p-2 pt-12 text-lg">
                        You can upload a csv file which will be used to populate
                        the field tags
                      </p>
                      <p className="p-2">
                        Example: If you have a tag <b>field_name</b> used in the
                        design, Kathai will automatically populate data from the cell where
                        column name is same as the <b>field_name</b>{" "}
                      </p>

                      <DataTable
                        pagination
                        highlightOnHover
                        columns={columns}
                        data={data}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  );
};

export default ExportConfigModal;
