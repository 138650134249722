/* eslint-disable jsx-a11y/alt-text */
import { React, useState } from "react";
import { getCurrentUser } from "../auth/getCurrentUser";
import { updateUserInfo } from "../services/user/getUserInfo";

import organization_name_grey from "../assets/KathaiIcons/organization_name_grey.png"
import organization_size_grey from "../assets/KathaiIcons/organization_size_grey.png"
import industry_grey from "../assets/KathaiIcons/industry_grey.png"
import department_grey from "../assets/KathaiIcons/department_grey.png"
import role_grey from "../assets/KathaiIcons/role_grey.png"
import organization_name_pink from "../assets/KathaiIcons/organization_name_pink.png"
import organization_size_pink from "../assets/KathaiIcons/organization_size_pink.png"
import industry_pink from "../assets/KathaiIcons/industry_pink.png"
import department_pink from "../assets/KathaiIcons/department_pink.png"
import role_pink from "../assets/KathaiIcons/role_pink.png"

const OnBoarding = ({ userData, setUserData }) => {
  const [organisationName, setOrganisationName] = useState("");
  const [organisationSize, setOrganisationSize] = useState("");
  const [industry, setIndustry] = useState("");
  const [department, setDepartment] = useState("");
  const [role, setRole] = useState("");

  const handleNameChange = (event) => {
    setOrganisationName(event.target.value);
  };
  const handleSizeChange = (event) => {
    setOrganisationSize(event.target.value);
  };
  const handleIndustryChange = (event) => {
    setIndustry(event.target.value);
  };
  const handleDepartmentChange = (event) => {
    setDepartment(event.target.value);
  };
  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    var userID = await getCurrentUser();
    console.log("userid", userID);
    var more = {
      organisationName: organisationName,
      organisationSize: organisationSize,
      industry: industry,
      department: department,
      role: role,
    };
    updateUserInfo(userID.id, more);

    setUserData({
      ...userData,
      ...(userData?.user && {}),
      user: {
        ...userData?.user,
        more,
      },
    });
  };

  return (
    // <div className="h-screen w-screen flex items-center justify-center">
    //   <div className="flex flex-col items-center md:w-3/5 2xl:w-2/5 3xl:w-1/5 leading-loose">
    //     <h1 className="text-4xl">Help us to know you more</h1>
    //     <hr className="mt-2 w-2/5 border-primary-500 border-1" />
    //     <br />

    <form
      class=" mx-auto rounded-lg shadow-xl overflow-hidden p-3 space-y-20"
      onSubmit={handleSubmit}
    >
      <div className="h-screen w-screen flex items-center justify-center">
        <div className="flex flex-col items-center md:w-3/5 2xl:w-2/5 3xl:w-1/5 leading-loose">
          <h1 className="text-4xl text-primary-600">Help us to know you more</h1>
          <hr className="mt-2 w-2/5 border-primary-500 border-1" />
          <br />
          <div className="p-2" />
          <div class="w-5/6 relative border-b-2">
            <input
              type="text"
              name="organisationname"
              placeholder=" "
              onChange={handleNameChange}
              value={organisationName}
              class="block w-full appearance-none focus:outline-none bg-transparent"
            />
            <label
              for="organisationname"
              class=" text-gray-400 absolute top-0 -z-1 duration-300 origin-0"
            >
              Organisation Name
            </label>
            <div className="absolute inset-y-0 right-2 flex items-center px-2 pointer-events-none">
              <img
                className="w-8 h-8 fill-current"
                viewBox="0 0 20 20"
                src={organisationName == "" ? organization_name_grey : organization_name_pink}
              ></img>
            </div>
          </div>

          <div className="p-2" />

          <div class="w-5/6 relative border-b-2">
            <select
              name="organisationsize"
              onChange={handleSizeChange}
              value={organisationSize}
              class="block w-full appearance-none focus:outline-none bg-transparent"
            >
              <option value="" hidden>
                {" "}
              </option>
              <option value="small">1-10 Employees</option>
              <option value="medium">11-20 Employees</option>
              <option value="large">21-50 Employees</option>
              <option value="xlarge">51-100 Employees</option>
              <option value="xxlarge">101+ Employees</option>
            </select>
            <label
              for="organisationsize"
              class="text-gray-400 absolute top-0 -z-1 duration-300 origin-0"
            >
              {organisationSize ? "" : "Organisation Size"}
            </label>
            <div className="absolute inset-y-0 right-1/4 flex items-center px-2 pointer-events-none">
              <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
                <path
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </div>
            <div className="absolute inset-y-0 right-2 flex items-center px-2 pointer-events-none">
              <img
                className="w-8 h-8   fill-current"
                viewBox="0 0 20 20"
                src={organisationSize == "" ? organization_size_grey : organization_size_pink}
              ></img>
            </div>
          </div>
          <div className="p-2" />
          <div class="w-5/6 relative border-b-2">
            <select
              name="industry"
              onChange={handleIndustryChange}
              value={industry}
              class="block w-full appearance-none focus:outline-none bg-transparent"
            >
              {/* <option value="" readonly="true" hidden="true" selected>Organisation Size</option> */}
              <option value="" hidden>
                {" "}
              </option>
              <option value="marketingagency">Marketing Agency</option>
            </select>
            <label
              for="industry"
              class="text-gray-400 absolute top-0 -z-1 duration-300 origin-0"
            >
              {industry ? "" : "Industry"}
            </label>
            <div className="absolute inset-y-0 right-1/4 flex items-center px-2 pointer-events-none">
              <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
                <path
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </div>
            <div className="absolute inset-y-0 right-2 flex items-center px-2 pointer-events-none">
              <img
                className="w-8 h-8 fill-current"
                viewBox="0 0 20 20"
                src={industry == "" ? industry_grey : industry_pink}
              ></img>
            </div>
          </div>

          <div className="p-2" />

          <div class="w-5/6 relative border-b-2">
            <input
              type="text"
              name="yourdepartment"
              placeholder=" "
              onChange={handleDepartmentChange}
              value={department}
              class="block w-full appearance-none focus:outline-none bg-transparent"
            />
            <label
              for="yourdepartment"
              class=" text-gray-400 absolute top-0 -z-1 duration-300 origin-0"
            >
              Your Department
            </label>
            <div className="absolute inset-y-0 right-2 flex items-center px-2 pointer-events-none">
              <img
                className="w-8 h-8 fill-current"
                viewBox="0 0 20 20"
                src={department == "" ? department_grey : department_pink}
              ></img>
            </div>
          </div>
          <div className="p-2" />

          <div class="w-5/6 relative border-b-2">
            <input
              type="text"
              name="yourrole"
              placeholder=" "
              onChange={handleRoleChange}
              value={role}
              class="block w-full appearance-none focus:outline-none bg-transparent"
            />
            <label
              for="yourrole"
              class=" text-gray-400 absolute top-0 -z-1 duration-300 origin-0"
            >
              Your Role
            </label>
            <div className="absolute inset-y-0 right-2 flex items-center px-2 pointer-events-none">
              <img
                className="w-8 h-8 fill-current"
                viewBox="0 0 20 20"
                src={role == "" ? role_grey : role_pink}
              ></img>
            </div>
          </div>
          <br />

          <button
            type="submit"
            className="pl-24 pr-24 mt-4 p-3 text-white hover:text-gray-200 bg-gradient-to-r from-primary-400 to-primary-600 darkShadow"
          >
            Submit
          </button>
          <button
            type="button"
            className=" text-gray-600 cursor-pointer underline"
            onClick={() => setUserData({ ...userData, skip: true })}
          >
            I'll do it later
          </button>
        </div>
      </div>
    </form>
  );
};

export default OnBoarding;
