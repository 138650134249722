import React, { useState, useEffect, useMemo, useRef } from "react";

import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";

import { getCurrentUserInfo } from "../services/user/getCurrentUserInfo";

import WelcomeModal from "../components/WelcomeModal";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Editor from "./Editor";
import Wizard from "./Wizard";
import OnBoarding from "../containers/OnBoarding";
import RecentStories from "./RecentStories";

const DetailsScreen = () => (
  <div className=" absolute w-screen h-screen bg-white">
    <img
      src="https://source.unsplash.com/random/?nature,water"
      alt=""
      className="w-full h-1/3"
      style={{
        overflow: "hidden",
        objectFit: "cover",
      }}
    />
    <div className="w-full flex">
      <div className="w-2/3 m-4 p-2 ">
        <h3 className="flex text-center pt-4 mb-1">
          {"Your Plot Card > Read this Plot Card"}
        </h3>
        <h1 className="text-primary-400 text-xl ">
          Lorem ipsum dolor sit amet, consectetur
        </h1>
        <span>
          <p className="pt-8">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Voluptatibus quia, nulla! Maiores et perferendis eaque,
            exercitationem praesentium nihil.Lorem ipsum dolor sit amet,
            consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et
            perferendis eaque, exercitationem praesentium nihil.Lorem ipsum
            dolor sit amet, consectetur adipisicing elit. Voluptatibus quia,
            nulla! Maiores et perferendis eaque, exercitationem praesentium
            nihil.Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Voluptatibus quia, nulla! Maiores et perferendis eaque,
            exercitationem praesentium nihil.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Voluptatibus quia, nulla! Maiores et perferendis eaque,
            exercitationem praesentium nihil.
          </p>
        </span>
        <h2 className="text-primary-400 text-xl mt-8">
          Lorem ipsum dolor sit amet, consectetur sas
        </h2>
      </div>
      <div className="w-1/3 m-auto pt-8">
        <h1 className="text-2xl">Category:</h1>
        <br />
        <div class="grid grid-flow-col grid-cols-2 grid-rows-3 gap-4">
          {new Array(4).fill("").map((_, ix) => (
            <div className="border-2 border-gray-800 p-2 m-2">
              Category {ix}
            </div>
          ))}
        </div>
        <br />
        <h1 className="text-2xl">Best for:</h1>
        <br />
        <div class="grid grid-flow-col grid-cols-2 grid-rows-3 gap-4">
          {new Array(4).fill("").map((_, ix) => (
            <div className="border-2 border-gray-800 p-2 m-2">Type {ix}</div>
          ))}
        </div>
      </div>
    </div>
  </div>
);
const params = (data) => {
  return Object.keys(data)
    .map((key) => `${key}=${encodeURIComponent(data[key])}`)
    .join("&");
};

const Dashboard = () => {
  const history = useHistory();
  let { path } = useRouteMatch();

  // disable jump action if not wizard route
  const { pathname } = useLocation();
  const disableJumpAction = useMemo(
    () => pathname.includes("wizard"),
    [pathname]
  );

  const [currentStep, setCurrStep] = useState(1);
  const [userData, setUserData] = useState({ loading: true });

  const paramsRef = useRef();

  const setCurrentStep = (step, params) => {
    if (step !== undefined) {
      setCurrStep(step);
    }
    paramsRef.current = params;

    if (!pathname.includes("wizard")) {
      history.push('/dashboard/wizard');
    }
  };
  useEffect(() => {
    if (currentStep === 3 && paramsRef.current) {
      // console.log("moving >>", paramsRef.current);

      history.push(`${path}/wizard/select-story?${params(paramsRef.current)}`);
    } else if (currentStep === 4 && paramsRef.current) {
      history.push(`/dashboard/editor?${params(paramsRef.current)}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, history]);

  useEffect(() => {
    const handleOnboard = async () => {
      const userinfo = await getCurrentUserInfo();
      // return userinfo not populated
      if (userinfo === null) return;

      const { id, more, ...rest } = userinfo;

      if (more && Object.keys(more).length > 0) {
        // console.log("No Onboarding,  user data", id, more, rest);
      } else {
        const lastRedirectTIme = window.localStorage.getItem(
          `${id}lastOnboardRedirectTime`
        );
        // console.log("lastOnboardRedirectTime", lastRedirectTIme);
        const currentTime = new Date();
        if (currentTime - lastRedirectTIme < 1000 * 60) {
          // console.log("last onboardingredirect was at", lastRedirectTIme);
        } else {
          window.localStorage.setItem(
            `${id}lastOnboardRedirectTime`,
            currentTime.getTime().toString()
          );
          // console.log("redirecting to onboarding at", lastRedirectTIme);
          // history.push("/onboarding");
        }
      }
      setUserData({ user: userinfo, loading: false });
      // console.log("user data", id, more, rest);
    };

    handleOnboard();
  }, []);

  let wizardRoutes = {
    "Define need": `${path}/wizard/define-need`,
    "Choose plot": `${path}/wizard/choose-plot`,
    "Select story": `${path}/wizard/select-story`,
    "Edit design": `${path}/wizard/edit-design`,
  };

  useEffect(() => {
    // TODO: add logic to permanently dismiss the popups here. ie if(!isDiabledPermanently) {<----enable three level toasts--->}
    for (let levelCount = 1; levelCount < 5; levelCount++) {
      if (!localStorage.getItem(`level${levelCount}_permanent_disable`)) {
        localStorage.setItem(`level${levelCount}`, true);
      }
    }
  }, []);

  if (userData?.loading) return "Loading...";

  return userData?.user?.more || userData?.skip ? (
    <div className="h-screen flex flex-col flex-nowrap">
      <div className="absolute">
        <Header userData={userData?.user} />
      </div>
      <div className="flex-1">
        <div className="flex bg-primarybg h-full">
          <Sidebar
            routePaths={wizardRoutes}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            disableJumpAction={disableJumpAction}
          />
          {/* {!!welcomeMode && <WelcomeModal />} */}
          <WelcomeModal username={userData?.user?.name ?? null} />
          <div className="pt-16 flex flex-1">
            <Switch>
              <Route path={`${path}/plotcards`}>
                {() => <h1>plotcards</h1>}
              </Route>
              <Route path={`${path}/recent-stories`}>
                <RecentStories setCurrentStep={setCurrentStep} />
              </Route>
              <Route path={`${path}/learn`}>{() => <h1>learn</h1>}</Route>
              <Route path={`${path}/details`}>
                <DetailsScreen />
              </Route>
              {/* <Route component={Editor} 
              path={`${path}/editor`}  /> */}
              <Route
                path={`${path}/editor`}

                render={(props) => (
                  <Editor {...props}
                    routePaths={wizardRoutes}
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep} />
                )}

              />
              <Route path={`${path}/wizard`}>
                <Wizard
                  routePaths={wizardRoutes}
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                />
              </Route>
              <Redirect to={`${path}/wizard`} />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <OnBoarding {...{ userData, setUserData }} />
  );
};

export default Dashboard;

const UserIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    className="feather feather-user"
  >
    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
    <circle cx="12" cy="7" r="4"></circle>
  </svg>
);
