import React, { useEffect } from "react";
import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom";

import Step2 from "./Step2";
import Step1 from "./Step1";
import Step3 from "./Step3";
import Step4 from "./Step4";

const TopIcon = ({
  step = 1,
  active = false,
  completed = false,
  title = "test",
  description = "Descso asj",
  onClick = console.log,
}) => (
  <div
    className={`flex flex-col text-gray-500 text-center cursor-pointer  ${
      active ? "text-primary-500 hover:text-primary-600" : ""
    } ${completed ? "text-primary-500 hover:text-primary-600" : ""}
    `}
    onClick={onClick}
  >
    <div
      style={{
        height: 50,
        width: 50,
        zIndex: 1,
      }}
      className={`border-4 border-transparent p-2 rounded-full text-gray-100 text-center align-middle wizardShadow ${
        active ? " border-primary-500 hover:bg-primary-100 bg-primary-100" : ""
      } ${completed ? "bg-primary-500 hover:bg-primary-600" : ""}
      ${!active && !completed && " text-gray-500 font-bold bg-primarybg"}`}
    >
      <h1 className={active && "text-primary-600"}>{step}</h1>
    </div>
    <div className="absolute mt-14 whitespace-nowrap">
      <span className={`text-md ${(active || completed) && "font-bold"}`}>
        {title}
      </span>
    </div>
    {/* <span className="text-sm font-thin">{description}</span> */}
  </div>
);

const manageSteps = (currentStep, gotoStep, setCurrentStep) => {
  if (gotoStep <= currentStep) {
    setCurrentStep(gotoStep);
    // console.log("Going to step", gotoStep);
  } else {
    // console.log("Can't go Forward");
  }
};

export const WizardHeader = ({ wizardTitles, currentStep, setCurrentStep }) => {
  const currentProgressWidth = (currentStep - 1) * 34;
  return (
    <div className="mx-12 mb-2">
      {/* Progressbar */}
      <div className="w-full flex justify-center text-center items-center ">
        <div
          className="shadow w-full mx-2 relative bg-gray-300 mt-4 h-5"
          style={{ marginBottom: "-2.2rem" }}
          // md:w-progressMedium md:mr-40 lg:w-progressLarge lg:mr-44  xl:w-progressxl xl:mr-44 2xl:w-progress2xl 2xl:mr-44 3xl:w-progress3xl 3xl:mr-60 "
        >
          <div
            className="bg-gradient-to-t from-primary-500 to-primary-400 h-full text-xs leading-none py-1 text-center text-white"
            style={{
              width: `${
                currentProgressWidth > 100 ? 100 : currentProgressWidth
              }%`,
            }}
          />
        </div>
      </div>
      {/* Step Icons */}
      <div className="w-full flex justify-between text-center items-center">
        {new Array(4).fill("").map((_, ix) => (
          <TopIcon
            style={{ width: "25%" }}
            step={ix + 1}
            title={wizardTitles[ix]}
            completed={currentStep > ix + 1}
            active={currentStep === ix + 1}
            onClick={() => manageSteps(currentStep, ix + 1, setCurrentStep)}
            description="step 1"
          />
        ))}
      </div>
    </div>
  );
};

const Wizard = (props) => {
  let { path, url } = useRouteMatch();
  let history = useHistory();
  var { routePaths, currentStep, setCurrentStep } = props;

  var wizardTitles =
    Object.keys(routePaths).length > 0 ? Object.keys(routePaths) : [];
  let stepRoutes = {};
  wizardTitles.forEach((routePath, idx) => {
    stepRoutes[idx + 1] = routePaths[routePath];
  });

  useEffect(() => {
    // TODO check render count
    if (currentStep) {
      history.push(stepRoutes[currentStep]);
    }
  }, [currentStep]);

  return (
    <div className="w-full h-full pt-10">
      <WizardHeader {...{ wizardTitles, currentStep, setCurrentStep }} />
      <Switch>
        <Route path={stepRoutes[1]}>
          <Step1 {...{ setCurrentStep }} />
        </Route>
        <Route path={stepRoutes[2]}>
          <Step2 {...{ setCurrentStep }} />
        </Route>
        <Route path={stepRoutes[3]}>
          <Step3 {...{ setCurrentStep }} />
        </Route>
        <Route path={stepRoutes[4]}>
          <Step4 {...{ setCurrentStep }} />
        </Route>
        {/* <Redirect from={path} to={`${path}/define-need`} /> */}

        {/* {currentStep === 3 && <Step3 {...{ setCurrentStep }} />} */}
      </Switch>
    </div>
  );
};

export default Wizard;
