import { appContextTypes } from "./app-context.types";

export const setIntentionTagDetails = (data) => {
  return {
    type: appContextTypes.SET_INTENTION_TAG_DETAILS,
    payload: data,
  };
};

export const resetToast = () => {
  return {
    type: appContextTypes.RESET_TOAST,
  };
};

export const setToast = (title, description, watchURL) => {
  return {
    type: appContextTypes.SET_TOAST,
    title,
    description,
    watchURL,
  };
};
