import React from "react";
import logo from "../assets/logo.png";

import { EyeIcon } from "@heroicons/react/outline";

import TagCard from "./TagCard";

const StoryCard = ({
  story,
  setCurrentStep,
  showActions = true,
  showReadMore = true,
  setSelectedStory,
  isMainPage = false,
}) => {
  return (
    <div
      className="max-w-lg rounded-lg overflow-hidden shadow-lg bg-white cardShadow border-4 hover:border-primary-400"
      style={{ maxWidth: 400 }}
    >
      <div>
        {/*  //? add a shadow or bg-gray-500 ? */}
        <div className="relative shadow-md rounded-b-md">
          {
            {
              true: (
                <img
                  src={
                    story?.templateId
                      ? `https://api.unlayer.com/v1/editor/24589/templates/${
                          story.templateId
                        }/thumbnail?t=${Date.now()}`
                      : logo
                  }
                  alt=""
                  className="w-full"
                  style={{ height: 250 }}
                />
              ),
              false: (
                <img
                  src={story?.thumbnailBlobUrl ?? logo}
                  alt=""
                  className="w-full"
                  style={{ height: 250 }}
                />
              ),
            }[isMainPage]
          }

          {/* overlay */}
          <div className="absolute top-2 right-2  w-full h-full">
            <div className="w-full h-full flex flex-row-reverse">
              <div className=" grid grid-cols-1 h-2/3 pr-2">
                {showActions && showReadMore && (
                  <button
                    onClick={() => setSelectedStory(story)}
                    className="h-10 w-10 bg-white text-gray-500 hover:bg-primary-500 hover:text-gray-200 text-center flex justify-center items-center rounded-full"
                  >
                    <EyeIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* updated design - buttons */}
        {showActions && (
          <div className="w-full flex flex-row justify-center items-center">
            <button
              onClick={() => {
                setSelectedStory && setSelectedStory(story);
                if (isMainPage) {
                  setCurrentStep(4, { template_id: story.templateId });
                } else {
                  setCurrentStep(4, { story_id: story.id });
                }
              }} // TODO: move this to the modal button click and update the function or set a route path with id:
              className="w-32 px-3 py-2  m-2 text-white hover:text-gray-200 bg-gradient-to-r from-primary-400 to-primary-600 darkShadow  whitespace-nowrap"
            >
              Use this
            </button>
          </div>
        )}

        {story ? (
          <div className="px-4 py-4">
            {isMainPage && (
              <>
                <h2 className="text-primary-500 text-xl">Tone:</h2>
                <div className="py-2">
                  {story?.tone ? (
                    story?.tone?.map((i) => <TagCard value={i} key={i} />)
                  ) : (
                    <br />
                  )}
                </div>

                <h2 className="text-primary-500 text-xl">Fields:</h2>
                <div className="py-2">
                  {story?.fields?.map((i) => (
                    <TagCard value={i} key={i} />
                  ))}
                </div>
              </>
            )}
          </div>
        ) : (
          <div class="border border-blue-300 shadow rounded-md p-4 max-w-sm w-full mx-auto">
            <div class="animate-pulse flex space-x-4">
              <div class="rounded-full bg-blue-400 h-12 w-12"></div>
              <div class="flex-1 space-y-4 py-1">
                <div class="h-4 bg-blue-400 rounded w-3/4"></div>
                <div class="space-y-2">
                  <div class="h-4 bg-blue-400 rounded"></div>
                  <div class="h-4 bg-blue-400 rounded w-5/6"></div>
                  <span>Loading</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StoryCard;
