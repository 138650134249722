import firebase from "firebase/app";

export const getUserInfo = async (userId) => {
  const userInfoDoc = await firebase
    .firestore()
    .collection("users")
    .doc(userId)
    .get();

  const userInfo = userInfoDoc.data();

  if (!userInfo) return null;

  return {
    ...userInfo,
    id: userInfoDoc.id,
  };
};

export const setUserInfo = async (userData) => {
  const userInfoDoc = await firebase
    .firestore()
    .collection("users")
    .doc(userData.id)
    .set(userData);

  const userInfo = userInfoDoc?.data();

  if (!userInfo) return null;

  return {
    ...userInfo,
    id: userInfoDoc.id,
  };
};
export const updateUserInfo = async (userID, userData) => {
  const doc = await firebase
    .firestore()
    .collection("users")
    .doc(userID).update({ "more": userData });
};
