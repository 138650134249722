import firebase from "firebase/app";

export const getGlobalConfig = async () => {
  const userInfoDoc = await firebase
    .firestore()
    .collection("global")
    .doc("config")
    .get();

  return userInfoDoc.data();
};
