import React from "react";
import { useRouteMatch, Link, useLocation } from "react-router-dom";
import Binocular from "../atoms/icons/Binocular";

// import kathaiLogo from "../assets/logo.png";
import kathaiLogo from "../assets/kathaiLogo.png";
import wizard from "../assets/KathaiIcons/Kathai_grey.png";
import wizardSelected from "../assets/KathaiIcons/Kathai_pink.png";
import plotCards from "../assets/KathaiIcons/plot_card_grey.png";
import plotCardsSelected from "../assets/KathaiIcons/plot_card_pink.png";
import recentStories from "../assets/KathaiIcons/recent_stories_grey.png";
import recentStoriesSelected from "../assets/KathaiIcons/recent_stories_pink.png";
import learn from "../assets/KathaiIcons/learn_grey.png";
import learnSelected from "../assets/KathaiIcons/learn_pink.png";

const SideIcon = ({ active = false, text = "test", img }) => (
  <div
    className={`flex flex-col w-full text-center cursor-pointer`}
    style={{
      color: active ? "rgba(220,91,103,168)" : "rgba(115,115,115,1)",
    }}
  >
    <div className={`p-2 pb-0 m-auto`}>{img || <Binocular />}</div>
    <span className="text-sm pb-10">{text}</span>
  </div>
);
const ImageIcon = ({ src, alt = "", className }) => (
  <img src={src} alt={alt.toLowerCase() || "image"} className={className} />
);
const Sidebar = (props) => {
  let { path } = useRouteMatch();

  let location = useLocation();
  // console.log(location);
  const { routePaths, currentStep, setCurrentStep, disableJumpAction } = props;
  // console.log("sidebar routePaths", routePaths);

  const onJumpTo = (e) => {
    var gotoStep = e.target.value;
    if (gotoStep <= currentStep) {
      setCurrentStep(gotoStep);
      // console.log("Going to step", gotoStep);
    } else {
      // console.log("Can't go Forward");
    }
  };
  return (
    <div className="w-40 min-w-10 bg-gray-900">
      <div className="flex flex-col items-center">
      <img
      src={kathaiLogo}
      alt="kathaiLogo"
      className="h-20 pb-2 object-contain"
      width={500}
      style={{margin: "0rem 0rem 0rem 0.2rem"}}
    />
        <Link to={`/dashboard`}>
          <SideIcon
            text="Kathai"
            active={location?.pathname?.includes("wizard")}
            img={
              location?.pathname?.includes("wizard") ? (
                <ImageIcon
                  src={wizardSelected}
                  alt="kathai"
                  className="w-16"
                />
              ) : (
                <ImageIcon src={wizard} alt="kathai" className="w-16" />
              )
            }
          />
        </Link>
        <Link to={`${path}/plotcards`}>
          <SideIcon
            text="Plot Cards"
            active={location?.pathname?.endsWith("/plotcards")}
            img={
              location?.pathname?.endsWith("/plotcards") ? (
                <ImageIcon
                  src={plotCardsSelected}
                  alt="kathai"
                  className="w-16"
                />
              ) : (
                <ImageIcon src={plotCards} alt="kathai" className="w-16" />
              )
            }
          />
        </Link>
        <Link to={`${path}/recent-stories`}>
          <SideIcon
            text="Recent Stories"
            active={location?.pathname?.endsWith("/recent-stories")}
            img={
              location?.pathname?.endsWith("/recent-stories") ? (
                <ImageIcon
                  src={recentStoriesSelected}
                  alt="kathai"
                  className="w-16"
                />
              ) : (
                <ImageIcon src={recentStories} alt="kathai" className="w-16" />
              )
            }
          />
        </Link>
        <Link to={`${path}/learn`}>
          <SideIcon
            text="Learn"
            active={location?.pathname?.endsWith("/learn")}
            img={
              location?.pathname?.endsWith("/learn") ? (
                <ImageIcon src={learnSelected} alt="kathai" className="w-16" />
              ) : (
                <ImageIcon src={learn} alt="kathai" className="w-16" />
              )
            }
          />
        </Link>
        <div className="p-4" />
        {/* {disableJumpAction && (
          <div class="w-5/6 relative border-b-2">
            <select
              name="jumpto"
              class="block w-full appearance-none focus:outline-none bg-transparent"
              onChange={onJumpTo}
              value={""}
            >
              <option value="" hidden>
                Go back to
              </option>

              {routePaths
                ? Object.keys(routePaths).map((routePath, idx) => {
                    return (
                      <option key={idx} value={idx + 1}>
                        {routePath}
                      </option>
                    );
                  })
                : null}
            </select>
            <div className="absolute inset-y-0 -right-2 flex items-center px-2 pointer-events-none">
              <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
                <path
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
};
export default Sidebar;
