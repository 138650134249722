import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

// dashboard/editor?template_id
const Step4 = (props) => {
  const location = useLocation();
  const history = useHistory();
  const search = props?.location?.search;
  const story_id = new URLSearchParams(search).get("story_id");

  useEffect(() => {
    // props.setCurrentStep(1);
    if (story_id) history.push(`/dashboard/editor?template_id=${story_id}`);
  }, []);

  if (!story_id) return <div>No Story Selected</div>;
  return <div>Please wait...</div>;
};

export default Step4;
