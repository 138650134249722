import React, { useState, useEffect } from "react";
import InfoIcon from "../atoms/icons/InfoIcon";
import AutoSuggestInput from "../components/auto-suggest-input/auto-suggest-input.component";

import { ReactComponent as TickImage } from "../assets/tick.svg";
import { ReactComponent as CloseImage } from "../assets/close.svg";
import { getSuggestions } from "../services/autosuggest";
import { useAppContext } from "../context/appContext/app-context";
import {
  setIntentionTagDetails,
  setToast,
} from "../context/appContext/app-context.actions";
import { errorToast, sortTags, successToast, warningToast } from "../utils";
import InformationToast from "../atoms/InformationToast";
import { TOAST_MESSAGES, TOOLTIP_MESSAGES } from "../constants/constants";
import { appContextTypes } from "../context/appContext/app-context.types";

const CardSelect = ({
  tag,
  title,
  toggleSelectedTag,
  selected,
  removeTagFromList,
}) => {
  return (
    <div
      className={`my-2 px-2  w-full h-10  sm:my-2 sm:px-2 sm:w-1/5 md:my-2 md:px-2 
      md:w-max lg:my-2 lg:px-2 
      lg:w-max xl:my-2 xl:px-2 
      xl:w-max
       border-2  m-2 p-2 cursor-pointer flex flex-row items-center justify-between whitespace-nowrap
        ${
          selected
            ? " bg-gray-500 text-gray-100 hover:bg-gray-600 hover:text-gray-100 "
            : "bg-gray-300 hover:text-white  hover:bg-gray-400"
        }`}
    >
      <span
        onClick={() => toggleSelectedTag(tag.id, selected)}
        className="mr-4"
      >
        {title}
      </span>
      {/* <TickImage
        onClick={() => toggleSelectedTag(tag.id, selected)}
        className="w-4 h-4"
      /> */}
      {selected ? (
        <></>
      ) : (
        <CloseImage
          onClick={() => removeTagFromList(tag.title)}
          className="w-4 h-4"
        />
      )}
    </div>
  );
};

const Step1 = ({ setCurrentStep }) => {
  const [requirementValue, setRequirementValue] = useState("");
  const [thirdPersonValue, setThirdPersonValue] = useState("");
  const [descriptionValue, setDescriptionValue] = useState("");
  const [tagData, setTagData] = useState([]);
  const [suggestionsData, setSuggestionsData] = useState(null);
  const [inputTextVector, setInputTextVector] = useState("");
  const [wordVectorsAvailable, setWordVectorsAvailable] = useState([]);

  useEffect(() => {
    getSuggestList();
  }, []);

  const { appState, appDispatch } = useAppContext();

  useEffect(() => {
    appDispatch({
      type: appContextTypes.SET_USERINPUT,
      payload: [requirementValue, thirdPersonValue, descriptionValue],
    });
  }, [requirementValue, thirdPersonValue, descriptionValue, appDispatch]);

  useEffect(() => {
    const hasValue = [
      requirementValue,
      thirdPersonValue,
      descriptionValue,
    ].find((i) => i !== "");
    // set local state from store
    if (!hasValue) {
      console.log(">>", appState.userInputs);
      const setItems = async () => {
        await getSuggestList();
        [setRequirementValue, setThirdPersonValue, setDescriptionValue].forEach(
          (i, ix) => {
            console.log(appState.userInputs[ix]);
            if (appState.userInputs[ix]) {
              i(appState.userInputs[ix]);
            }
          }
        );
        // setTagData((tagData) => {
        //   console.log("test1", tagData);
        //   console.log("test2", appState.intentionTagDetails);
        //   return tagData.map((i) => ({
        //     ...i,
        //     isSelected: appState.intentionTagDetails.includes(
        //       i?.title.toLowerCase()
        //     ),
        //   }));
        // });
      };
      setItems();
    }
  }, []);
  //

  const removeTagFromList = (title) => {
    const modifiedData = tagData.filter((item) => item.title !== title);
    setTagData(modifiedData);
  };

  const getSuggestList = async () => {
    try {
      const suggestionsResponse = await getSuggestions();
      setSuggestionsData(suggestionsResponse[0]);
      let vectorData;
      Object.keys(suggestionsResponse[0]).forEach((key) => {
        if (key !== "id")
          vectorData = Object.assign(suggestionsResponse[0][key]);
      });
      setWordVectorsAvailable(vectorData);
    } catch (error) {
      errorToast("suggestions api failed");
    }
  };

  // console.log("app state", appState);

  const toggleSelectedTag = (id, currentSelected) => {
    const modifiedTagData = tagData.map((tag) => {
      if (tag.id === id) {
        return { ...tag, isSelected: !currentSelected };
      }
      return tag;
    });

    setTagData(modifiedTagData);
  };

  const goToNextstep = () => {
    const selectedIntentionTags = tagData
      .filter((item) => item.isSelected)
      .map((tag) => tag.title);

    selectedIntentionTags.push(requirementValue);
    selectedIntentionTags.push(thirdPersonValue);
    selectedIntentionTags.push(descriptionValue);

    appDispatch(
      setIntentionTagDetails([
        ...new Set(
          selectedIntentionTags
            .map((i) => i && i.trim())
            .filter((i) => i !== "")
        ),
      ])
    );
    setCurrentStep(2);
  };

  const getSearchCombo = (text) => {
    var comboArray = [];

    comboArray.push(text);
    if (String(text).endsWith("s")) {
      comboArray.push(text.slice(0, -1));
      if (String(text).endsWith("es")) {
        comboArray.push(text.slice(0, -2));
      }
    }
    if (String(text).endsWith("y")) {
      comboArray.push(text.slice(0, -1) + "ies");
    }
    if (
      String(text).endsWith("a") ||
      String(text).endsWith("i") ||
      String(text).endsWith("o") ||
      String(text).endsWith("u")
    ) {
      comboArray.push(text + "es");
    } else {
      comboArray.push(text + "s");
    }

    return comboArray;
  };
  const shuffle = (a) => {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  };
  const getRelevantTagList = async () => {
    var ignorelist = [
      "aboard",
      "across",
      "after",
      "against",
      "along",
      "amid",
      "among",
      "around",
      "as",
      "at",
      "before",
      "behind",
      "below",
      "beneath",
      "beside",
      "between",
      "beyond",
      "but",
      "by",
      "concerning",
      "considering",
      "despite",
      "down",
      "during",
      "except",
      "following",
      "for",
      "from",
      "in",
      "inside",
      "into",
      "like",
      "minus",
      "near",
      "next",
      "of",
      "off",
      "on",
      "onto",
      "opposite",
      "out",
      "outside",
      "over",
      "past",
      "per",
      "plus",
      "regarding",
      "round",
      "save",
      "since",
      "than",
      "through",
      "till",
      "to",
      "toward",
      "under",
      "underneath",
      "unlike",
      "until",
      "up",
      "upon",
      "versus",
      "via",
      "with",
      "within",
      "without",
      "is",
      "was",
      "am",
      "a",
      "an",
      "were",
      "where",
      "they",
      "he",
      "she",
      "it",
      "can",
      "cannot",
      "does",
      "not",
      "if",
      "else",
      "while",
      "do",
      "i",
      "you",
      "them",
      "themselves",
      "",
      " ",
    ];

    // Remove Punctuations and Symbols
    var cleanSentence = inputTextVector.match(/[^_\W]+/g)?.join(" ");
    // Seperates each word
    var wordVectors = cleanSentence ? cleanSentence.split(" ") : [];
    wordVectors = wordVectors.filter((e) => !ignorelist.includes(e));
    var tempTagData = [];
    var finalTagData = [];

    const selectedTagsMap = {};

    tagData.forEach((v, idx) => {
      if (v?.isSelected) {
        finalTagData.push(v);
        selectedTagsMap[v?.title?.toLowerCase()] = true;
      }
    });
    wordVectors.forEach((v, idx) => {
      var searchKey = v.toLocaleLowerCase();
      var searchcombos = getSearchCombo(searchKey);
      searchcombos.forEach((searchKey) => {
        for (var obj in wordVectorsAvailable) {
          var found = false;
          if (searchKey === obj) {
            found = true;
          }
          // wordVectorsAvailable[obj]
          //   .map((name) => name.toLowerCase().replace(" ", ""))
          //   .filter((x) => {
          //     if (x.indexOf(searchKey) !== -1) {
          //       found = true;
          //     }
          //   });
          // .includes(searchKey);

          if (found) {
            var relevantTags = wordVectorsAvailable[obj];
            tempTagData = tempTagData.concat(
              relevantTags.filter(
                (item) =>
                  item != " " &&
                  !tempTagData.includes(item.toLowerCase().replace(" ", ""))
              )
            );
          }
        }
      });
    });

    // tempTagData = shuffle([...new Set(tempTagData)]);
    tempTagData = [...new Set(tempTagData)];

    tempTagData.forEach((tag, idx) => {
      console.log("test1", tempTagData);
      console.log("test2", appState.intentionTagDetails);
      if (!selectedTagsMap[tag])
        finalTagData.push({
          id: idx,
          title: tag.toLowerCase().trim(),
          isSelected:
            appState.intentionTagDetails?.length > 0
              ? appState.intentionTagDetails.includes(tag.toLowerCase().trim())
              : false,
        });
    });

    const cleanedTagData = Array.from(
      new Set(finalTagData.map((a) => a.title))
    ).map((title) => {
      return finalTagData.find((a) => a.title === title);
    });

    setTagData(sortTags(cleanedTagData));
  };

  const setTextVector = () => {
    setInputTextVector(
      requirementValue + " " + thirdPersonValue + " " + descriptionValue
    );
  };

  useEffect(() => {
    getRelevantTagList();
  }, [inputTextVector]);

  useEffect(() => {
    setTextVector();
  }, [requirementValue, thirdPersonValue, descriptionValue]);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("level1"))) {
      appDispatch(
        setToast(
          TOAST_MESSAGES.LEVEL1.TITLE,
          TOAST_MESSAGES.LEVEL1.DESCRIPTION,
          TOAST_MESSAGES.LEVEL1.WATCH_LINK
        )
      );
    }
  }, []);

  // console.log("appState", appState);
  return (
    <>
      <div className="m-12 text-gray-600 leading-10">
        <div className="flex text-gray-500 text-2xl text-center pt-4 font-thin">
          Define
          <div className="pt-1 pl-1 has-tooltip">
            <InfoIcon />
            <span className="tooltip font-medium">
              {TOOLTIP_MESSAGES.STEP1.DEFINE}
            </span>
          </div>
        </div>
        <span className="text-primary-500 text-3xl font-semibold pt-2">
          What is purpose of your story?
        </span>
        <br />
        <br />
        <br />
        {/* fitst row */}
        <div className="text-3xl pt-2 flex flex-row justify-between max-w-full">
          <div className="text-3xl self-end text-black font-semibold whitespace-nowrap m-2">
            I want to send
          </div>
          <AutoSuggestInput
            data={
              suggestionsData
                ? Object.keys(suggestionsData?.emailType) ?? []
                : []
            }
            value={requirementValue}
            setValue={(value) => setRequirementValue(value)}
            topText="mention the type of email"
            exampleText=" (Ex: Seasonal, Promotional etc.)"
          />
          <div className="text-3xl self-end text-black font-bold whitespace-nowrap m-2">
            email to
          </div>
        </div>
        {/* second row */}
        <div className="text-3xl pt-2 flex flex-row justify-between max-w-full">
          <AutoSuggestInput
            data={
              suggestionsData
                ? Object.keys(suggestionsData?.emailType) ?? []
                : []
            }
            value={thirdPersonValue}
            setValue={(value) => setThirdPersonValue(value)}
            topText="mention your audience"
            exampleText=" (Ex: Paid customers, subscribers etc.)"
          />
          <div className="text-3xl self-end text-black font-bold whitespace-nowrap m-2">
            so that
          </div>

          <AutoSuggestInput
            data={
              [] // No Auto fill
              // suggestionsData
              //   ? Object.keys(suggestionsData?.emailType) ?? []
              //   : []
            }
            value={descriptionValue}
            setValue={(value) => setDescriptionValue(value)}
            topText="objective of the email"
            exampleText=""
          />
        </div>
        <br />
        {tagData?.length > 0 && (
          <>
            <br />
            <div className="flex text-gray-500 text-2xl text-center pt-4 font-thin">
              Add Details
              <div className="pt-1 pl-1 has-tooltip">
                <InfoIcon />
                <span className="tooltip font-medium">
                  {TOOLTIP_MESSAGES.STEP1.ADD_DETAILS}
                </span>
              </div>
            </div>
            <span className="text-primary-500 text-3xl font-semibold pt-2">
              Let's make it more relevant
            </span>
            <br />
          </>
        )}

        <br />
        <div className="flex flex-wrap -mx-2 overflow-hidden sm:-mx-2 md:-mx-2 lg:-mx-2 xl:-mx-2 overflow-y-auto h-30">
          {tagData.slice(0, 12).map((tag) => (
            <CardSelect
              key={tag.id}
              tag={tag}
              title={tag.title}
              selected={tag.isSelected}
              removeTagFromList={removeTagFromList}
              toggleSelectedTag={(id, currentSelected) =>
                toggleSelectedTag(id, currentSelected)
              }
            />
          ))}
        </div>
        <br />
        <div className="w-full flex justify-center">
          <button
            className="text-xl mt-4 p-3 text-white hover:text-gray-200 bg-gradient-to-r from-primary-400 to-primary-600 darkShadow"
            onClick={() => goToNextstep()}
          >
            <span className="mx-4 text-lg font-semibold">
              Show me plot cards for my story
            </span>
          </button>
        </div>
        <br />
      </div>
      {JSON.parse(localStorage.getItem("level1")) && (
        <InformationToast level={1} />
      )}
    </>
  );
};

export default Step1;
