import { toast } from "tailwind-toast";

export const groupByPlotCardIds = (ids = [], property = "plotcardId") => {
  //groupById
  return ids.reduce((acc, obj) => {
    let key = obj[property];
    if (!acc[key]) {
      acc[key] = 0;
    }
    acc[key] = acc[key] + 1;
    return acc;
  }, {});
};

export const sortByRelevantCount = (groupByObj = {}) => {
  const arr = [];

  for (const [key, value] of Object.entries(groupByObj)) {
    arr.push({ plotcardId: key, count: value });
  }

  return arr.sort((a, b) => b.count - a.count);
};

export const warningToast = (text) => {
  toast().warning(text, "").show();
};
export const successToast = (text) => {
  toast().success(text, "").show();
};
export const errorToast = (text) => {
  toast().danger(text, "").show();
};

export const sortTags = (tagData) => {
  tagData.sort((a, b) =>
    b.isSelected > a.isSelected ? 1 : a.isSelected > b.isSelected ? -1 : 0
  );

  return tagData;
};
