import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import "./App.css";
import { useAuth } from "./auth";

import Login from "./containers/Login";
import Signup from "./containers/Signup";
import Dashboard from "./containers/Dashboard";
import OnBoarding from "./containers/OnBoarding";
import PublicRoute from "./atoms/PublicRoute";
import PrivateRoute from "./atoms/PrivateRoute";
import ForgotPassword from "./containers/ForgotPassword";
import { Context } from "./context/UserContext";
import { AppProvider } from "./context/appContext/app-context";

const App = () => {
  const { isLoading, user } = useAuth();

  // useEffect(() => {}, []);
  return (
    <Context.Provider value={user}>
      <AppProvider>
        <Router>
          <Switch>
            <PublicRoute
              restricted={true}
              component={Login}
              path="/signin"
              exact
            />
            <PublicRoute
              restricted={true}
              component={ForgotPassword}
              path="/forgot-password"
              exact
            />
            <PublicRoute
              restricted={true}
              component={Signup}
              path="/signup"
              exact
            />
            <PrivateRoute
              isLoading={isLoading}
              isAuthed={!!user}
              component={OnBoarding}
              path="/onboarding"
              exact
            />
            <Route exact path="/">
              <Redirect to="/dashboard" />
            </Route>
            <PrivateRoute
              isLoading={isLoading}
              isAuthed={!!user}
              component={Dashboard}
              path="/dashboard"
            />
          </Switch>
        </Router>
      </AppProvider>
    </Context.Provider>
  );
};

export default App;
