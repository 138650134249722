import React, { useEffect, useState } from "react";
import InfoIcon from "../atoms/icons/InfoIcon";
import InformationToast from "../atoms/InformationToast";
import PlotCard from "../atoms/PlotCard";
import PlotCardDetailsModal from "../components/PlotCardDetailsModal";
import { TOAST_MESSAGES, TOOLTIP_MESSAGES } from "../constants/constants";
import { useAppContext } from "../context/appContext/app-context";
import { setToast } from "../context/appContext/app-context.actions";
import { getPlotCardByTags } from "../services/plotCards";
import { groupByPlotCardIds, sortByRelevantCount } from "../utils";

const pagesize = 9;

const Select = ({ options = [] }) => {
  return (
    <div className="relative inline-block w-full text-gray-700">
      <select
        className="w-full h-10 pl-3 pr-6 text-base placeholder-gray-600 border appearance-none focus:shadow-outline bg-transparent"
        placeholder="Regular input"
      >
        {options.map((i) => (
          <option>{i}</option>
        ))}
      </select>
      <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
        <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
          <path
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clip-rule="evenodd"
            fill-rule="evenodd"
          ></path>
        </svg>
      </div>
    </div>
  );
};

const defaultTags = ["offering launch", "travel"];

let allResults = [];

const Step2 = ({ setCurrentStep }) => {
  const [cardIds, setCardIds] = useState([]); //TODO pass these id's into Card components.
  const [isPcLoading, setIsPcloading] = useState(true);
  const [isCardsRelatedToTags, setIsCardsRelatedToTags] = useState(true);
  const [selectedPlotCard, setSelectedPlotCard] = useState();
  const {
    appState: { intentionTagDetails: tags },
    appDispatch,
  } = useAppContext();

  useEffect(() => {
    // Fetch all plotcards relevent for tags
    const fetchPlotCards = async () => {
      try {
        let cardIds = [];
        if (tags?.length > 0) {
          cardIds = await getPlotCardByTags(tags);
          setIsCardsRelatedToTags(true);
        }
        if (cardIds.length === 0) {
          cardIds = await getPlotCardByTags(defaultTags);
          setIsCardsRelatedToTags(false);
        }
        const cardsGroupById = groupByPlotCardIds(cardIds); // Groupby
        const sortedPlotcards = sortByRelevantCount(cardsGroupById); //Sortby

        if (sortedPlotcards) {
          allResults = sortedPlotcards;

          if (sortedPlotcards?.length >= pagesize) {
            setCardIds(sortedPlotcards?.slice(0, pagesize));
          } else {
            setCardIds(sortedPlotcards);
          }
          setIsPcloading(false);
        }
      } catch (e) {
        console.error("Error while read from db", e.message);
        setIsPcloading(false);
      }
    };
    fetchPlotCards();
  }, []);

  const loadMore = () => {
    // still more than 1 page
    if (allResults?.length > cardIds?.length + pagesize) {
      setCardIds(allResults?.slice(0, cardIds?.length + pagesize));
    } else {
      setCardIds(allResults);
    }
  };
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("level2"))) {
      appDispatch(
        setToast(
          TOAST_MESSAGES.LEVEL2.TITLE,
          TOAST_MESSAGES.LEVEL2.DESCRIPTION,
          TOAST_MESSAGES.LEVEL2.WATCH_LINK
        )
      );
    }
  }, []);

  return (
    <>
      <PlotCardDetailsModal
        selectedPlotCard={selectedPlotCard}
        setSelectedPlotCard={setSelectedPlotCard}
        setCurrentStep={setCurrentStep}
      />
      <div className="w-full p-4">
        <div className="w-full flex justify-between pl-6">
          <div className=" text-gray-600 leading-10">
            <span className="text-black-400 font-bold pt-2 pr-2">
              Selected tags:
            </span>
            {(tags ?? defaultTags).join(",")}
            <br />

            <div className="flex text-gray-500 text-2xl text-center pt-4 font-thin pb-2">
              Choose
              <div className="pt-3 pl-2 has-tooltip">
                <InfoIcon />
                <span className="tooltip font-medium">
                  {TOOLTIP_MESSAGES.STEP2.DEFINE}
                </span>
              </div>
            </div>
            <span className="text-primary-500 text-3xl font-semibold pt-2">
              Pick a plot card that’s most relevant
            </span>
            <br />
            <br />
          </div>
          <div className="m-12 text-gray-600 leading-10 flex">
            <div className="pr-4">
              {/* hide untill we have this functionality */}
              {/* <Select options={["Filter"]} /> */}
            </div>
            {/* <div className="pr-4">
              <Select options={["Best for"]} />
            </div> */}
          </div>
        </div>
        {!isPcLoading && cardIds?.length === 0 && (
          <div
            class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 m-6"
            role="alert"
          >
            <p class="font-bold">No Plotcards found!</p>

            <p>Please retry with different tagnames</p>
          </div>
        )}

        {isPcLoading ? (
          <Loading />
        ) : (
          <>
            <div
              className={`ml-4 grid gap-20 ${
                cardIds?.length >= 3
                  ? "grid-cols-3 2xl:grid-cols-4"
                  : "grid-cols-3"
              }`}
            >
              {(cardIds ?? []).map((i) => (
                <PlotCard
                  key={i?.plotcardId}
                  plotCardId={i?.plotcardId}
                  setCurrentStep={setCurrentStep}
                  setSelectedPlotCard={setSelectedPlotCard}
                />
              ))}
            </div>
            {allResults?.length !== cardIds?.length && (
              <div className="w-full flex justify-center my-10">
                <button
                  className="p-3 text-white hover:text-gray-100 bg-gradient-to-r from-primary-400 to-primary-600 darkShadow  whitespace-nowrap font-bold"
                  onClick={() => loadMore()}
                >
                  Show me more plot cards
                </button>
              </div>
            )}
          </>
        )}
      </div>
      {JSON.parse(localStorage.getItem("level2")) && (
        <InformationToast level={2} />
      )}
    </>
  );
};

const BlurBG = () => (
  <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center"></div>
);
const Loading = () => (
  <div className="loader m-6 ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4" />
);

export default Step2;
