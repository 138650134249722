import firebase from "firebase/app";
import axios from 'axios';
import { getGlobalConfig } from "../user";

const bucketName = "kathai-6af04.appspot.com";

const prefix = `https://storage.googleapis.com/${bucketName}/`;


export const getStorageUrls = (uid, storyId) => {
  var designURL = prefix + `stories/uid/${uid}/${storyId}/design.json`;
  var thumbnailURL = prefix + `stories/uid/${uid}/${storyId}/thumbnail.png`;
  return { designURL: designURL, thumbnailURL: thumbnailURL }
}

const mimeTypeToExtension = {
  "image/png": ".png",
  "image/jpeg": ".jpg" // not needed.
}

export const uploadFile = async (file, fileExtension, folderName) => {
  // console.log("File", file);
  const filePath = folderName + fileExtension;
  const storage = firebase.storage().ref(filePath);
  const uploadTask = await storage.put(file);
  //   return prefix + filePath;
  return uploadTask.ref.getDownloadURL();
};

export const uploadDesignJson = async (designJson, uid, storyId) => {
  console.log('designjson', designJson, uid, storyId);
  const designJsonBlob = await createBlobFromJson(designJson)
  const folderName = `stories/uid/${uid}/${storyId}/design`;
  const publicUrl = await uploadFile(designJsonBlob, ".json", folderName);

  return publicUrl;
};

export const createBlobFromJson = (jsonObj) => {
  return new Blob([JSON.stringify(jsonObj, null, 2)], { type: 'application/json' });
}

export const uploadThumbnail = async (thumbnailBlob = new Blob(), uid, storyId) => {
  const folderName = `stories/uid/${uid}/${storyId}/thumbnail`;
  const fileExtension = mimeTypeToExtension[thumbnailBlob.type];
  const publicUrl = await uploadFile(thumbnailBlob, fileExtension, folderName);

  return publicUrl;
}


/**
 * Get design json from URL
 * @param {String} url
 * @returns JSONObject
 */

export const getDesignJsonFromUrl = async (url = "") => {

  var designjson = await axios.get(url)
    .then((response) => {
      console.log('get design', response)
      if (response.status === 200) {
        return response.data
      }
      else {
        return null
      }
    })

  console.log('loaded designjson', designjson)
  if (designjson)
    return designjson
  else
    throw new Error("Failed to get the resource from firestorage.");

  // const storagePublicUrl = new URL(url);
  // if (storagePublicUrl.href) {
  //   const headers = new Headers({ "Content-Type": "application/json" });

  //   const request = new Request(encodeURI(storagePublicUrl), {
  //     method: "GET",
  //     headers: headers,
  //     cache: "no-cache", // TODO: test the cache sccenario
  //   });
  //   const response = await fetch(request);
  //   console.log("Response", response);
  //   if (response.ok) {
  //     const contentType = response.headers.get("content-type");
  //     if (!contentType || !contentType.includes("application/json")) {
  //       throw new TypeError("Oops, we haven't got JSON!");
  //     }
  //     return response.json();
  //   } else {
  //     console.log("Response", response);
  //     throw new Error("Failed to get the resource from firestorage.");
  //   }
  // }
};


// TODO: move this token to env
// Convert API key to base64 first

let headers;

export const getHeaders = async () => {
  if (headers) return;
  const config = await getGlobalConfig();
  let token = Buffer.from(config?.unlayerId).toString("base64");

  headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    authorization: `Basic ${token}`,
  };
  console.log("<><><><>", headers);
};


export const exportImage = async (designjson, mergeTags={}) => {
  const body = JSON.stringify({
    displayMode: 'email',
    design: designjson,
    mergeTags: mergeTags //{first_name: 'John', last_name: 'Doe'}
  })
  if (!headers) await getHeaders();

  var imageUrl = await axios.post('https://api.unlayer.com/v2/export/image', body, { headers })
    .then(async (response) => {
      if (response.status == 200) {
        console.log('Thumbnail Url updated', response.data.data.url)
        return response.data.data.url
      }
      else {
        return null
      }
    })

  if (imageUrl)
    return imageUrl
  else
    throw new Error("Failed to export as Image");

}

export const exportPDF = async (designjson, mergeTags={}) => {
  const body = JSON.stringify({
    displayMode: 'email',
    design: designjson,
    mergeTags: mergeTags //{first_name: 'John', last_name: 'Doe'}
  })
  if (!headers) await getHeaders();

  var pdfUrl = await axios.post('https://api.unlayer.com/v2/export/pdf', body, { headers })
    .then(async (response) => {
      if (response.status == 200) {
        console.log('Thumbnail Url updated', response.data.data.url)
        return response.data.data.url
      }
      else {
        return null
      }
    })

  if (pdfUrl)
    return pdfUrl
  else
    throw new Error("Failed to export as PDF");

}

export const exportZIP = async (designjson, mergeTags={}) => {
  const body = JSON.stringify({
    displayMode: 'email',
    design: designjson,
    mergeTags: mergeTags //{first_name: 'John', last_name: 'Doe'}
  })
  if (!headers) await getHeaders();

  var pdfUrl = await axios.post('https://api.unlayer.com/v2/export/zip', body, { headers })
    .then(async (response) => {
      if (response.status == 200) {
        console.log('Thumbnail Url updated', response.data.data.url)
        return response.data.data.url
      }
      else {
        return null
      }
    })

  if (pdfUrl)
    return pdfUrl
  else
    throw new Error("Failed to export as PDF");

}