import { Fragment, useState, useRef, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import YouTube from "react-youtube";
import { setUserInfo } from "../services/user/getUserInfo";
import { getCurrentUserInfo } from "../services/user";
import { WELCOME_SECTION_CONSTANTS } from "../constants/constants";

const opts = {
  height: "200",
  width: "300",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 0,
  },
};

export default function WelcomeModal(props) {
  const [open, setOpen] = useState(false);
  const mounted = useRef();
  useEffect(() => {
    if (mounted.current === true) return;
    const showModalForFirstLogin = async () => {
      const user = await getCurrentUserInfo();
      console.log(user)

      if (!user) return;
      if (user?.lastLogin) {
        // not first time user
        return setUserInfo({
          ...user,
          lastLogin: new Date(
            Date.now() + new Date().getTimezoneOffset() * 60000
          ).getTime(),
        });
      } else {        
        // first time user -loaded after onboarding 
        setOpen(true);
        return setUserInfo({
          ...user,
          lastLogin: new Date(
            Date.now() + new Date().getTimezoneOffset() * 60000
          ).getTime(),
        });
      }
    };
    showModalForFirstLogin();
  }, []);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={open}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left items-center">
                    <div className="w-full m-auto flex justify-end">
                      <button className="p-2" onClick={() => setOpen(false)}>
                        X
                      </button>
                    </div>
                    <div className="w-full m-auto flex justify-center p-4">
                      <YouTube
                        videoId={WELCOME_SECTION_CONSTANTS.INTRODUCTION_YOUTUBE_LINK}
                        opts={opts}
                        // onReady={this._onReady}
                      />
                    </div>
                    <Dialog.Title
                      as="h3"
                      className="text-3xl leading-6 text-center  font-medium text-gray-900"
                    >
                      Hey {props.username ? props.username : "User"},
                    </Dialog.Title>
                    <div className="mt-4">
                      <p className="text-md pt-4 text-gray-900 font-medium">
                        Kathai is a story generator that helps transform mundane
                        stats and data into interesting stories that capture the
                        attention of your target audience. Understand how to
                        build your story with this short tutorial.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <br />
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
