import firebase from "firebase/app";

const collections = {
  TagIndexPlotCards: "tagindexplotcards",
};

export const getPlotCards = async () => {
  const querySnapshot = await firebase
    .firestore()
    .collection("plotcards")
    .get();

  const cards = querySnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));

  return cards;
};

export const getPlotCardById = async (plotCardId) => {
  const plotcardDoc = await firebase
    .firestore()
    .collection("plotcards")
    .doc(plotCardId)
    .get();

  return plotcardDoc.data();
};

/**
 * tagNames - all selected tags
 * @returns - all matched plotcard Id's
 */
export const getPlotCardByTags = async (tagNames = []) => {
  const querySnapshot = await firebase
    .firestore()
    .collection(collections.TagIndexPlotCards)
    .where(
      "tagName",
      "in",
      tagNames.map((item) => item.trim(""))
    )
    .get();
  const plotcardId = querySnapshot.docs.map((doc) => ({ ...doc.data() }));

  return plotcardId;
};

/**
 * Add Like
 * input - uid, plotcardId
 * @returns - true or false
 */
export const toggleLike = async (uid, plotcardId, isLike) => {
  if (uid && plotcardId) {
    const plotcardCollection = firebase.firestore().collection("plotcards");
    const plotcardDoc = plotcardCollection.doc(plotcardId);

    // Already liked
    const querySnapshot = await plotcardDoc
      .collection("likes")
      .where("userId", "==", uid)
      .get();
    if (querySnapshot.size > 0) {
      console.error("Already user liked!");
      // Unlike if the user already Liked.
      runTransactionLikeUnLike(uid,plotcardDoc, isLike);
      return;
    }

    runTransactionLikeUnLike(uid, plotcardDoc, isLike);
  
  }
};

const runTransactionLikeUnLike = (uid, plotcardDoc, isLike = true) =>{
  var newDoc = plotcardDoc.collection("likes").doc(uid);

    const likeDoc = {
      userId: uid,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    return firebase.firestore().runTransaction(function (transaction) {
      return transaction.get(plotcardDoc).then(function (doc) {
        transaction.update(plotcardDoc, {
          totalLikes: firebase.firestore.FieldValue.increment(isLike ? 1 : -1),
        });
        if (isLike){
            return transaction.set(newDoc, likeDoc)
          }
          else {
            return transaction.delete(newDoc)
          } 
      });
    });
}

/**
 * Get like by plotcardId, uid
 */
export const getLikeByPlotCard = async (uid, plotcardId) => {
  if (uid && plotcardId) {
    const plotCardDoc = firebase
      .firestore()
      .collection("plotcards")
      .doc(plotcardId);

    const likeDoc = plotCardDoc.collection("likes").where("userId", "==", uid);

    const likeDocSnapshot = await likeDoc.get();

    const likes = likeDocSnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    return likes;
  }
};

export const subscribeToLike = async (userId,plotcardId, cb) => {
  const callback = async (querySnapshot) => {

    const likes = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    //Only expect first doc in the query snapshot.
    if(likes.length > 0){
      cb(likes[0])
    }else{
      cb(null);
    }
  };

  return firebase.firestore()
        .collection("plotcards")
        .doc(plotcardId)
        .collection("likes")
        .where("userId", "==", userId)
        .onSnapshot(callback);
};
