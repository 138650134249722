import React, { useState, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";

import { signIn, signInWithGoogle } from "../auth";

import logo from "../assets/logo.png";
import frontImage from "../assets/frontImage.png";
import email_grey from "../assets/KathaiIcons/email_grey.png";
import email_pink from "../assets/KathaiIcons/email_pink.png";
import lock_pink from "../assets/KathaiIcons/lock_pink.png";
import lock_grey from "../assets/KathaiIcons/lock_grey.png";
import Input from "../atoms/Input";
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState(""); // TODO: add any element for rendering error msg.

  let history = useHistory();

  const onSignIn = useCallback(async () => {
    try {
      await signIn(email, password);
      history.push("/");
    } catch (e) {
      setErrorMsg(e.message);
    }
  }, [email, password]);

  const onSignInWithGoogle = useCallback(async () => {
    try {
      await signInWithGoogle();
      history.push("/");
    } catch (e) {
      setErrorMsg(e.message);
    }
  }, []);

  const onInputEmail = useCallback(
    (e) => {
      setEmail(e.target.value);
      if (errorMsg) setErrorMsg("");
    },
    [errorMsg]
  );

  const onInputPassword = useCallback(
    (e) => {
      setPassword(e.target.value);
      if (errorMsg) setErrorMsg("");
    },
    [errorMsg]
  );

  return (
    <div className="h-screen w-screen">
      <div className="w-screen flex justify-end px-4">
        <img src={logo} className="p-4" alt="app logo" />
      </div>
      <div className="w-screen flex justify-between px-4 my-4 2xl:my-24">
        <div className=" bg-white shadow-lg w-1/3 2xl:w-96 border-4 border-primary-400">
          <div className="p-4 flex flex-col items-center text-center w-full">
            <h1
              className="text-6xl py-2 text-primary-500"
              style={{ fontFamily: "EB Garamond" }}
            >
              Hi there!
            </h1>

            <p className="my-2 2xl:my-4 text-xl drop-shadow-lg font-light">
              Once upon a time… there was a mailer and then it became a KATHAI…
            </p>
            <button
              onClick={onSignInWithGoogle}
              className="w-full m-4 2xl:m-6 p-3 bg-gray-300 text-primary-500 text-xl font-semibold darkShadow"
            >
              Sign in with Google
            </button>
            <span className="pt-3">or</span>
            <br />
            <div id="input-container" className="w-full">
              <img
                src={email ? email_pink : email_grey}
                alt="app logo"
                width={30}
              />

              <Input
                type="email"
                className="w-full p-2 pl-8 border border-gray-800 my-4"
                placeholder="email"
                onChange={onInputEmail}
                value={email}
              />
            </div>
            <div id="input-container" className="w-full">
              <img
                src={password ? lock_pink : lock_grey}
                alt="app logo"
                width={30}
              />

              <Input
                type="password"
                className="w-full p-2 pl-8 border border-gray-800 my-4"
                placeholder="password"
                value={password}
                onChange={onInputPassword}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onSignIn();
                  }
                }}
              />
            </div>

            <br />
            <button
              className="text-xl w-full mt-4 p-3 text-white hover:text-gray-200 bg-gradient-to-r from-primary-400 to-primary-600 darkShadow"
              onClick={onSignIn}
              type="submit"
            >
              Sign in
            </button>
            {errorMsg && <p className="text-red-500 p-2">{errorMsg}</p>}
            <div className="w-full flex justify-between text-gray-500 underline p-3">
              <Link to="/forgot-password">Forgot password</Link>
              <Link to="/signup">Sign Up</Link>
            </div>
          </div>
        </div>
        <img
          src={frontImage}
          className="p-4 mb-4 w-2/3 object-cover"
          alt="app logo"
        />
      </div>
    </div>
  );
};

export default Login;
