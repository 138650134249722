import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import EmailEditor from "react-email-editor";
import { getCurrentUserInfo } from "../services/user/getCurrentUserInfo";
import {
  createUserStory,
  updateUserStory,
  getUserStory,
} from "../services/story/index";
import {
  uploadDesignJson,
  uploadThumbnail,
  getStorageUrls,
  getDesignJsonFromUrl,
} from "../services/storage/index";
import { exportImage } from "../services/storage/index";

import sample from "../assets/sample.json";
import ExportConfigModal from "../components/ExportConfigModal";

import { useAppContext } from "../context/appContext/app-context";
import InformationToast from "../atoms/InformationToast";
import { setToast } from "../context/appContext/app-context.actions";
import { TOAST_MESSAGES } from "../constants/constants";

import { WizardHeader } from "./Wizard";

const Editor = (props) => {
  const { appState, appDispatch } = useAppContext();
  const emailEditorRef = useRef();
  const [isTemplateNameEditing, setIsTemplateNameEditing] = useState(false);
  const [editorOptions, setEditorOptions] = useState({
    appearance: {
      theme: "light", //dark
      panels: {
        tools: {
          dock: "right", //left
        },
      },
    },
    projectId: 24589,
  });
  const [templateName, setTemplateName] = useState("Click to rename story");
  const [storyId, setStoryId] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [timer, setTimer] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [exportModal, setExportModal] = useState(false);
  const history = useHistory();

  const search = props.location.search;
  const story_id = new URLSearchParams(search).get("story_id");
  const template_id = new URLSearchParams(search).get("template_id");

  var { routePaths, currentStep, setCurrentStep } = props;

  const onDesignUpdate = (updates) => {
    console.log("onDesignUpdated", updates);
    // saveDesignAndThumbnail()
  };

  const onDesignLoad = (data) => {
    console.log("onDesignLoad");
    saveDesignAndThumbnail();
  };

  const onLoad = () => {
    console.log("onLoad");
    if (emailEditorRef.current) {
      // emailEditorRef.current.editor.loadBlank();
      emailEditorRef.current.editor.addEventListener(
        "design:updated",
        onDesignUpdate
      );
      console.log(emailEditorRef.current.editor);
    }
  };

  const authUser = async () => {
    // Check Auth Meta
    const userinfo = await getCurrentUserInfo();
    // return userinfo not populated
    if (userinfo === null) return;

    // console.log('userinfo', userinfo)
    setUserInfo(userinfo);
  };

  const createNewStory = async (template_uuid) => {
    if (userInfo === null) return;
    var storyObj = {
      author: userInfo.id,
      template_uuid: template_uuid,
      isPublic: false,
      designBlobUrl: null,
      thumbnailBlobUrl: null,
    };
    var newStoryID = await createUserStory(storyObj);
    var urls = getStorageUrls(userInfo.id, newStoryID);

    var newStoryObj = {
      designBlobUrl: urls.designURL,
      thumbnailBlobUrl: urls.thumbnailURL,
    };

    await updateUserStory(newStoryID, newStoryObj);
    history.replace(`/dashboard/editor?story_id=${newStoryID}`);

    setStoryId(newStoryID);
  };

  const getStoryDetails = async (story_id, template_id) => {
    // Check Story Meta
    if (story_id === null && template_id === null) {
      console.log("story and template id null, creating blank story");
      await createNewStory(template_id);
    } else if (story_id === null && template_id != null) {
      console.log("creating user story from template", template_id);
      // Fetch Design from unlayer using templateid
      var options = editorOptions;
      options.templateId = template_id;
      setEditorOptions({ ...options });

      // Create new story id based on template
      await createNewStory(template_id);
    } else if (story_id != null && template_id == null) {
      console.log("Loading user story", story_id);
      var storyInfo = await getUserStory(story_id);
      var designjson = await getDesignJsonFromUrl(storyInfo.designBlobUrl);
      emailEditorRef.current.editor.loadDesign(designjson);
      setStoryId(story_id);
    } else {
      // Both template_id and story_id mentioned
      console.log(
        "Both template_id and story_id mentioned",
        story_id,
        template_id
      );
      console.log("Loading user story", story_id);
      // Fetch Story from firebase
      var storyInfo = await getUserStory(story_id);
      console.log("storyinfo", storyInfo);
      setStoryId(story_id);
    }
  };

  useEffect(() => {
    authUser();
    if (JSON.parse(localStorage.getItem("level4"))) {
      appDispatch(
        setToast(
          TOAST_MESSAGES.LEVEL4.TITLE,
          TOAST_MESSAGES.LEVEL4.DESCRIPTION,
          TOAST_MESSAGES.LEVEL4.WATCH_LINK
        )
      );
    }
  }, []);

  useEffect(() => {
    getStoryDetails(story_id, template_id);
  }, [userInfo]);

  useEffect(() => {
    autoSave();
  }, [storyId]);
  // useEffect(() => {
  //   saveDesignAndThumbnail()
  // }, [hasChanged])

  useEffect(() => {
    return () => {
      clearInterval(timer);
    };
  }, []);

  const saveDesignAndThumbnail = async () => {
    // const userInfo = await getCurrentUserInfo();
    if (userInfo === null || storyId === null) return;
    setIsSaving(true);
    if (
      !isSaving &&
      emailEditorRef?.current &&
      emailEditorRef?.current !== null
    ) {
      emailEditorRef?.current?.editor?.exportHtml(async (data) => {
        const { design, html } = data;

        var storyObj = {};
        try {
          var designUrl = await uploadDesignJson(design, userInfo.id, storyId);
          // storyObj.designBlobUrl = designUrl
          console.log("design updated", designUrl);
        } catch (e) {
          console.log("Error Uploading design json", e);
          setIsSaving(false);
        }
        try {
          var imageUrl = await exportImage(design);
          storyObj = {
            designBlobUrl: designUrl,
            thumbnailBlobUrl: imageUrl,
          };
          await updateUserStory(storyId, storyObj);
          console.log("story data updated", storyId, storyObj);
        } catch (e) {
          console.log("Error Saving data", e);
          setIsSaving(false);
        }
      });
      setIsSaving(false);
    }
  };

  const autoSave = () => {
    clearInterval(timer);
    var temptimer = setInterval(() => {
      // console.log('Auto Save called')
      if (storyId === null || userInfo === null) {
        return;
      }
      saveDesignAndThumbnail();
    }, 10000);
    setTimer(temptimer);
  };

  const tools = {};

  var wizardTitles =
    Object.keys(routePaths).length > 0 ? Object.keys(routePaths) : [];
  let stepRoutes = {};
  wizardTitles.forEach((routePath, idx) => {
    stepRoutes[idx + 1] = routePaths[routePath];
  });

  return (
    <>
      <ExportConfigModal
        exportModal={exportModal}
        setExportModal={setExportModal}
        emailEditorRef={emailEditorRef}
      />
      <div className="editor__container flex flex-col flex-1">
        {currentStep == 4 && (
          <WizardHeader
            {...{ wizardTitles, currentStep, setCurrentStep }}
            className="pt-20"
          />
        )}
        <div className="aditor__toolbar flex justify-center items-center relative p-3 pt-5">
          {/* <div className="absolute left-0 text-sm text-gray-400 pl-1.5">
            Story ID : <span>{storyId}</span>
          </div> */}
          {/* <div key="htmlPreview" ref={htmlPreviewElementRef} style={{ maxheight: "200px", overflow: "hidden" }}
          dangerouslySetInnerHTML={{
            __html: htmlPreview
          }}
        /> */}
          {isTemplateNameEditing ? (
            <div className="mr-80">
              <input
                style={{ borderBottom: "1px solid rgb(232, 93, 94)" }}
                className="pl-1/5 bg-transparent border-primary-500 text-lg outline-none"
                onChange={(evt) => {
                  setTemplateName(evt.target.value);
                }}
                type="text"
                value={templateName}
              />
              <button
                className="pl-1.5 text-primary-500"
                onClick={(evt) => {
                  setIsTemplateNameEditing(!isTemplateNameEditing);
                }}
                style={{ verticalAlign: "middle" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </button>
            </div>
          ) : (
            <div
              className="pr-80 text-lg italic"
              onClick={(evt) => {
                setIsTemplateNameEditing(!isTemplateNameEditing);
              }}
            >
              <h1>{templateName}</h1>
            </div>
          )}
          <button
            className="text-sm p-2 absolute right-36 text-white hover:text-gray-100 bg-gradient-to-r from-primary-400 to-primary-600 hover:shadow-lg"
            onClick={() => setExportModal(true)}
          >
            Export HTML
          </button>
        </div>
        <div className="editor__canvas flex-1">
          {editorOptions.templateId && (
            <EditorComp
              innerRef={emailEditorRef}
              options={editorOptions}
              tools={tools}
              onLoad={onLoad}
              onDesignLoad={onDesignLoad}
              minHeight="100%"
              key={
                editorOptions.templateId ? editorOptions.templateId : "editor"
              }
            />
          )}
        </div>
      </div>
      {JSON.parse(localStorage.getItem("level4")) && (
        <InformationToast level={4} />
      )}
    </>
  );
};

const EditorComp = (props) => {
  return <EmailEditor ref={props.innerRef} {...props} />;
};

export default Editor;
