import React, { useState, useEffect } from "react";
import { getCurrentUser } from "../auth";
import { getRecentStories } from "../services/story";

import StoryCard from "../atoms/StoryCard";
import StoryCardDetailsModal from "../components/StoryCardDetailsModal";

const RecentStories = ({ setCurrentStep }) => {
  const [stories, setStories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedStory, setSelectedStory] = useState();

  console.log("selectedStory", selectedStory);

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      try {
        const { id } = getCurrentUser();
        const recentStories = await getRecentStories(id);
        console.log("Recent Stories", recentStories);
        setStories(recentStories);
        setLoading(false);
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
    };
    init();
  }, []);
  return (
    <>
      <StoryCardDetailsModal
        selectedStory={selectedStory}
        setSelectedStory={setSelectedStory}
        setCurrentStep={setCurrentStep}
      />
      <div className="w-full p-4">
        <div className="w-full flex justify-between pl-6">
          <div className=" text-gray-600 leading-10">
            <span className="text-primary-400 text-xl font-bold">
              Recent Stories
            </span>
            <br />
          </div>
        </div>
        <div
          className={`ml-4 grid gap-2 max-h-screen overflow-y-auto ${
            stories?.length >= 3 ? "grid-cols-3 2xl:grid-cols-4" : "grid-cols-2"
          }`}
        >
          {(stories ?? []).map((story) => (
            <StoryCard
              key={story?.id}
              story={story}
              setSelectedStory={setSelectedStory}
              setCurrentStep={setCurrentStep}
            />
          ))}
          {!loading && stories?.length === 0 && (
            <div
              className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 m-6"
              role="alert"
            >
              <p className="font-bold">No stories found!</p>

              <p>You haven't created any templates yet !</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default RecentStories;
