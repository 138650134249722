import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import InfoIcon from "../atoms/icons/InfoIcon";
import InformationToast from "../atoms/InformationToast";
import StoryCard from "../atoms/StoryCard";
import StoryCardDetailsModal from "../components/StoryCardDetailsModal";
import { TOAST_MESSAGES, TOOLTIP_MESSAGES } from "../constants/constants";
import { useAppContext } from "../context/appContext/app-context";
import { setToast } from "../context/appContext/app-context.actions";
import { appContextTypes } from "../context/appContext/app-context.types";
import { getStoriesByTags } from "../services/story";

const Step3 = ({ setCurrentStep }) => {
  const history = useHistory();
  const location = useLocation();

  const [stories, setStories] = useState([]);
  const [loading, setLoading] = useState();
  const [selectedStory, setSelectedStory] = useState();

  const { appState, appDispatch } = useAppContext();
  const search = location?.search;
  const tags = appState?.intentionTagDetails ?? [];

  let selectedPlotCardId = new URLSearchParams(search).get("plotCardId");
  if (selectedPlotCardId === null) {
    selectedPlotCardId = appState.selectedPlotcardId;
  }

  useEffect(() => {
    // set plotcardId to store
    if (selectedPlotCardId) {
      appDispatch({
        type: appContextTypes.SET_SELECTED_PLOTCARD,
        payload: +selectedPlotCardId,
      });
    }
  }, []);

  const selectedTags = appState.intentionTagDetails;

  // console.log("plot id :", selectedPlotCardId, "select̥edTags", selectedTags);

  useEffect(() => {
    const getStoriesList = async () => {
      setLoading(true);
      const storiesResponse = await getStoriesByTags(
        selectedTags,
        selectedPlotCardId + ""
      );
      // console.log("stories response", storiesResponse);

      setStories(storiesResponse);
      setLoading(false);
    };

    getStoriesList();
  }, [selectedPlotCardId, selectedTags]);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("level3"))) {
      appDispatch(
        setToast(
          TOAST_MESSAGES.LEVEL3.TITLE,
          TOAST_MESSAGES.LEVEL3.DESCRIPTION,
          TOAST_MESSAGES.LEVEL3.WATCH_LINK
        )
      );
    }
  }, [appDispatch]);

  return (
    <>
      <StoryCardDetailsModal
        selectedStory={selectedStory}
        setSelectedStory={setSelectedStory}
        setCurrentStep={setCurrentStep}
        isMainPage={true}
      />
      <div className="w-full p-4">
        <div className="w-full flex justify-between pl-6">
          <div className=" text-gray-600 leading-10">
            <span className="text-black-400 font-bold pt-2 pr-2">
              Selected tags:
            </span>
            {(tags ?? []).join(",")}
            <br />

            <div className="flex text-gray-500 text-2xl text-center pt-4 font-thin pb-2">
              Select
              <div className="pt-2 pl-1 has-tooltip">
                <InfoIcon />
                <span className="tooltip font-medium">{TOOLTIP_MESSAGES.STEP3.DEFINE}</span>
              </div>
            </div>
            <span className="text-primary-500 text-3xl font-semibold pt-2">
              Your Story
            </span>
            <br />
            <br />
          </div>
        </div>
        <div
          className={`ml-4 grid gap-2 max-h-screen overflow-y-auto grid-cols-3 2xl:grid-cols-4 pb-5`}
        >
          {(stories ?? []).map((story) => (
            <StoryCard
              key={story?.id}
              story={story}
              setCurrentStep={setCurrentStep}
              setSelectedStory={setSelectedStory}
              isMainPage
            />
          ))}
          {!loading && stories?.length === 0 && (
            <div
              class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 m-6"
              role="alert"
            >
              <p class="font-bold">No stories found!</p>

              <p>Please retry with different plotcard</p>
            </div>
          )}
        </div>
        {JSON.parse(localStorage.getItem("level3")) && (
          <InformationToast level={3} />
        )}
      </div>
    </>
  );
};

export default Step3;
