import React, { useEffect, useState } from "react";
import logo from "../assets/logo.png";

import { HeartIcon } from "@heroicons/react/outline";
import { HeartIcon as HeartIconSolid } from "@heroicons/react/solid";

import TagCard from "./TagCard";
import {
  toggleLike,
  getPlotCardById,
  subscribeToLike,
} from "../services/plotCards";
import { getCurrentUser } from "../auth";

const PlotCard = ({ plotCardId, setCurrentStep, setSelectedPlotCard }) => {
  const [data, setData] = useState();
  const [isLike, setIsLike] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      const plotCardDetails = await getPlotCardById(plotCardId);
      setData(plotCardDetails);
      console.log(plotCardId, plotCardDetails);
    };
    loadData();
  }, []);

  useEffect(() => {
    const { id } = getCurrentUser();
    const unsubscribe = subscribeToLike(id, plotCardId, (like) => {
      if (like) setIsLike(true);
    });
    return unsubscribe;
  }, []);

  const handleLike = (likeOrUnlike) => async () => {
    
    const { id } = getCurrentUser();
    try {
      await toggleLike(id, plotCardId, likeOrUnlike);
      setIsLike(likeOrUnlike);
    } catch (e) {
      console.error("Error in Like : ", e);
    }
  };
  return (
    <div
      className="max-w-lg rounded-lg overflow-hidden shadow-lg bg-white cardShadow border-4 hover:border-primary-400"
      style={{ maxWidth: 400 }}
    >
      <div>
        {/*  //? add a shadow or bg-gray-500 ? */}
        <div className="relative rounded-b-md p-3">
          <img
            src={data?.thumbnail ?? logo}
            alt=""
            className="object-contain w-full"
            style={{ height: 250 }}
          />
          {/* overlay */}
          <div className="absolute top-2 right-2  w-full h-full">
            <div className="w-full h-full flex flex-row-reverse">
              <div className=" grid grid-cols-1 h-2/3 p-4">
                <button
                  to="/details"
                  className={`${
                    isLike ? "" : "hover:bg-primary-500"
                  } h-10 w-10 bg-gray-200 text-gray-500 hover:text-gray-200 text-center flex justify-center items-center rounded-full`}
                >
                  {/* <HeartIcon className="h-6 w-6" aria-hidden="true" /> */}
                  {isLike ? (
                    <HeartIconSolid
                      className="h-6 w-6 text-primary-500"
                      aria-hidden="true"
                      onClick={handleLike(false)}
                    />
                  ) : (
                    <HeartIcon
                      onClick={handleLike(true)}
                      className="h-6 w-6"
                      aria-hidden="true"
                    />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* updated design - buttons */}
        {data ? (
          <>
            <div className="w-full flex flex-row justify-center items-center">
              <button
                onClick={() => setCurrentStep(3, { plotCardId })}
                className="w-32 px-3 py-2  m-2 text-white hover:text-gray-100 bg-gradient-to-r from-primary-400 to-primary-600 darkShadow  whitespace-nowrap font-bold"
              >
                Use this
              </button>
              <button
                className="w-32  m-1 text-xs xl:text-base text-center rounded-sm py-2 px-3 text-gray-800 bg-gray-200 border-2 border-gray-300 hover:border-gray-400 hover:bg-gray-400 hover:text-gray-100 whitespace-nowrap darkShadow"
                onClick={() => {
                  setSelectedPlotCard({ ...data, plotCardId });
                }}
              >
                Read more
              </button>
            </div>

            <div className="px-6 py-4">
              <p
                className="text-base font-bold mb-6 line-clamp-3"
                style={{
                  lineHeight: "1.5em",
                  minHeight: "4.5em",
                }}
              >
                {data?.description}
              </p>

              <h2 className="text-primary-500 text-xl">Category:</h2>
              <div className="py-2">
                {data?.categories ? (
                  data?.categories?.map((i) => <TagCard value={i} key={i} />)
                ) : (
                  <br />
                )}
              </div>

              <h2 className="text-primary-500 text-xl">Best for:</h2>
              <div className="py-2">
                {data?.best?.map((i) => (
                  <TagCard value={i} key={i} />
                ))}
              </div>
            </div>
          </>
        ) : (
          <div class="border border-gray-300 shadow rounded-md p-4 max-w-sm w-full mx-auto">
            <div class="animate-pulse flex space-x-4">
              <div class="rounded-full bg-gray-400 h-12 w-12"></div>
              <div class="flex-1 space-y-4 py-1">
                <div class="h-4 bg-gray-400 rounded w-3/4"></div>
                <div class="space-y-2">
                  <div class="h-4 bg-gray-400 rounded"></div>
                  <div class="h-4 bg-gray-400 rounded w-5/6"></div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PlotCard;
