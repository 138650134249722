import React from "react";

export default function Alert({ message, success = false, error = false }) {
  return (
    <div
      className={`text-white px-6 py-4 border-0 rounded relative m-4 ${
        success && "bg-green-500"
      } ${error && "bg-black"}`}
    >
      <span className="text-xl inline-block mr-5 align-middle">
        <i className="fas fa-bell" />
      </span>
      <span className="inline-block align-middle mr-8">
        <b className="capitalize">Alert!</b> {message}
      </span>
    </div>
  );
}
