import { React, useState } from "react";

import Profile from "../components/Profile";
import search from "../assets/KathaiIcons/search_grey.png";
import searchSelected from "../assets/KathaiIcons/search_pink.png";

const Header = (props) => {
  const [searchFocus, setSearchFocus] = useState(false);


  const handleSearchFocus = () => {
    setSearchFocus(true)
  };

  const handleSearchBlur = () => {
    setSearchFocus(false)
  };

  return (
  <div className="w-screen bg-transparent flex justify-end align-baseline items-center filter pt-4">
    <div className="flex items-center">
      <div className="relative mx-auto text-gray-600">
      <div className="absolute inset-y-0 flex items-center px-2 pointer-events-none">
          <img
            className="w-8 h-8 fill-current"
            viewBox="0 0 20 20"
            src={searchFocus? searchSelected:search}
          ></img>
        </div>

        <input
          className="border-2 border-gray-300 h-10 px-10 pr-16 text-md focus:outline-none"
          style={{backgroundColor: "rgba(226,229,236,255)"}}
          name="search"
          placeholder="Search"
          onFocus={handleSearchFocus}
          onBlur={handleSearchBlur}
        />
      
        <button type="submit" className=""></button>
      </div>
    </div>
    <Profile userData={props.userData} />
  </div>
  )
};
export default Header;
