import React from "react";

const TagCard = ({ value, className }) => {
  return (
    <span
      className="inline-block p-2 text-sm  mr-2 mb-2 text-left bg-primary-100 text-gray-500 rounded-sm "
      style={{ minWidth: "6rem" }}
      key={value}
    >
      {value ?? ""}
    </span>
  );
};

export default TagCard;
