export const TOAST_MESSAGES = {
  LEVEL1: {
    TITLE: "User Story",
    DESCRIPTION:
      "Tell us why you’re creating this story and we will help you figure out how you can tell your story so that you accomplish your goals.",
    WATCH_LINK: "https://www.youtube.com/watch?v=dQw4w9WgXcQ",
  },
  LEVEL2: {
    TITLE: "Plot Picking",
    DESCRIPTION:
      "The theme that you want to set the message in is what we call as the plot. In this section you pick the concept that your story will be based on.",
    WATCH_LINK: "https://www.youtube.com/watch?v=dQw4w9WgXcQ",
  },
  LEVEL3: {
    TITLE: "Story Selection",
    DESCRIPTION:
      "Select the story that can help you frame the message of your Kathai the best, using the plot you picked.",
    WATCH_LINK: "https://www.youtube.com/watch?v=dQw4w9WgXcQ",
  },
  LEVEL4: {
    TITLE: "Design Edit",
    DESCRIPTION:
      "Customize the design of the story you’ve picked, change the elements to suit your needs and brand guidelines, then download your Kathai for use.",
    WATCH_LINK: "https://www.youtube.com/watch?v=dQw4w9WgXcQ",
  },
};

export const TOOLTIP_MESSAGES = {
  STEP1: {
    DEFINE: "lorem ipsum",
    ADD_DETAILS: "lorem ipsum add details",
  },
  STEP2: {
    DEFINE: "lorem ipsum",
  },
  STEP3: {
    DEFINE: "lorem ipsum",
  },
  PLOTCARD_DETAILS: { DEFINE: "lorem ipsum plot card" },
};

export const WELCOME_SECTION_CONSTANTS = {
  INTRODUCTION_YOUTUBE_LINK: "NpEaa2P7qZI",
};
