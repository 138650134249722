import React, { useState, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import logo from "../assets/logo.png";
import frontImage from "../assets/frontImage.png";
import email_grey from "../assets/KathaiIcons/email_grey.png";
import email_pink from "../assets/KathaiIcons/email_pink.png";
import lock_pink from "../assets/KathaiIcons/lock_pink.png";
import lock_grey from "../assets/KathaiIcons/lock_grey.png";
import user_pink from "../assets/KathaiIcons/user_pink.png";
import user_grey from "../assets/KathaiIcons/user_grey.png";

import Alert from "../atoms/Alert";
import { SpinIcon } from "../atoms/icons/Spin";
import { createAccount } from "../auth";
import { createUser } from "../services/user";
import Input from "../atoms/Input";

const SignUp = () => {
  const [username, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;
    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    } else if (name === "name") {
      setName(value);
    }
    if (error) setError("");
  };

  const onSignUp = useCallback(async () => {
    // console.log("Signing up...", username, email, password);

    setIsLoading(true);

    // validating input
    if (username === "" || email === "" || password === "") {
      console.error("Invalid user details");
      setError("please provide valid inputs");
      setIsLoading(false);
      return;
    }

    // TODO: email validation

    try {
      const { uid } = await createAccount(email, password);
      await createUser(uid, {
        name: username,
        email,
      });
      setIsLoading(false);

      history.push("/signin");
    } catch (e) {
      console.error("Error while creating new user");
      setError(e.message);
      setIsLoading(false);
    }
  }, [username, email, password]);

  return (
    <div className="h-screen w-screen">
      <div className="w-screen flex justify-end px-4">
        <img src={logo} className="p-4" alt="app logo" />
      </div>
      <div className="w-screen flex justify-between px-4 my-4 2xl:my-24">
        <div className=" bg-white shadow-lg w-1/3 2xl:w-96 border-4 border-primary-400">
          <div className="p-4 flex flex-col items-center text-center w-full ">
            <h1
              className="text-6xl py-6 text-primary-500"
              style={{ fontFamily: "EB Garamond" }}
            >
              Hi there!
            </h1>

            <p className="my-4 text-xl drop-shadow-lg font-light">
              Once upon a time… there was a mailer and then it became a KATHAI…
            </p>
            {/* <button
              onClick={onSignInWithGoogle}
              className="w-full m-6 p-3 bg-gray-300 text-primary-500 hover:bg-gray-200 text-xl font-semibold darkShadow"
            >
              Sign in with Google
            </button> 
            <span className="pt-3">or</span>
            <hr className="w-4/5 mt-3" /> */}
            <br />
            <div className="w-full">
              <div id="input-container" className="w-full">
                <img
                  src={username ? user_pink : user_grey}
                  alt="app logo"
                  width={30}
                />

                <Input
                  placeholder="Name"
                  type="text"
                  spellcheck="false"
                  name="name"
                  className="w-full p-2 pl-8 border border-gray-800 my-4"
                  onChange={onChangeHandler}
                />
              </div>
              <div id="input-container" className="w-full">
                <img
                  src={email ? email_pink : email_grey}
                  alt="app logo"
                  width={30}
                />

                <Input
                  type="email"
                  className="w-full p-2 pl-8 border border-gray-800 my-4"
                  placeholder="Email"
                  name="email"
                  spellcheck="false"
                  onChange={onChangeHandler}
                />
              </div>
              <div id="input-container" className="w-full">
                <img
                  src={password ? lock_pink : lock_grey}
                  alt="app logo"
                  width={30}
                />

                <Input
                  type="password"
                  className="w-full p-2 pl-8 border border-gray-800 my-4"
                  placeholder="Password"
                  name="password"
                  onChange={onChangeHandler}
                />
              </div>
            </div>

            <br />
            <button
              onClick={onSignUp}
              className="text-xl w-full mt-4 p-3 text-white hover:text-gray-200 bg-gradient-to-r from-primary-400 to-primary-600 darkShadow"
            >
              {isLoading && <SpinIcon />}
              Sign up
            </button>
          </div>
          {error && <Alert error message={error} />}
          <p class="text-sm text-center text-gray-400 pb-3">
            Already have an account ?{" "}
            <Link
              to="/signin"
              class="text-primary-400 focus:outline-none focus:underline focus:text-primary-500 dark:focus:border-indigo-800"
            >
              Sign in
            </Link>
            .
          </p>
        </div>
        <img
          src={frontImage}
          className="p-4 w-2/3 object-cover"
          alt="app logo"
        />
      </div>
    </div>
  );
};

export default SignUp;
