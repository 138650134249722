import React from "react";
import logo from "../assets/logo.png";

const StoryCardDetailsModal = ({
  selectedStory,
  setSelectedStory,
  setCurrentStep,
  isMainPage = false,
}) => {
  const showModal = !!selectedStory;
  return (
    <div>
      {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            onClick={(e) => {
              e.stopPropagation();
              setSelectedStory(false);
            }}
          >
            <div
              className="relative w-auto my-6 mx-auto"
              style={{ maxWidth: "90%" }}
            >
              {/*content*/}
              <div
                className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none"
                style={{
                  backgroundColor: "#e7e7e7",
                  minHeight: "50vh",
                  minWidth: "50vw",
                  maxHeight: "90vh",
                }}
              >
                {/*header*/}
                {/*body*/}
                <div className="relative p-6 flex-auto overflow-auto max-h-full">
                  <div
                    className="flex-1 h-full w-full py-12"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {
                      {
                        true: (
                          <img
                            src={
                              selectedStory?.templateId
                                ? `https://api.unlayer.com/v1/editor/24589/templates/${
                                    selectedStory.templateId
                                  }/thumbnail?t=${Date.now()}`
                                : logo
                            }
                            alt="test"
                            className="object-contain w-full"
                            style={{
                              maxHeight: "60vh",
                            }}
                          />
                        ),
                        false: (
                          <img
                            src={selectedStory?.thumbnailBlobUrl ?? logo}
                            alt="test"
                            className="object-contain w-full"
                            style={{
                              maxHeight: "50vh",
                            }}
                          />
                        ),
                      }[isMainPage]
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  );
};

export default StoryCardDetailsModal;
