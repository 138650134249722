import React, { createContext, useContext, useReducer } from "react";
import { appContextTypes } from "./app-context.types";

export const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);

const initialState = {
  intentionTagDetails: [],
  userInputs: [],
  selectedPlotcardId: undefined,
  toastInformation: {
    isVisible: false,
    title: "Test",
    description: "",
    watchURL: "",
  },
};

export const configReducer = (state, action) => {
  switch (action.type) {
    case appContextTypes.SET_INTENTION_TAG_DETAILS:
      return { ...state, intentionTagDetails: action.payload };
    case appContextTypes.SET_TOAST:
      return {
        ...state,
        toastInformation: {
          ...state.toastInformation,
          isVisible: true,
          title: action.title,
          description: action.description,
          watchURL: action.watchURL,
        },
      };
    case appContextTypes.SET_USERINPUT:
      return {
        ...state,
        userInputs: action.payload,
      };
    case appContextTypes.SET_SELECTED_PLOTCARD:
      return {
        ...state,
        selectedPlotcardId: action.payload,
      };
    case appContextTypes.RESET_TOAST:
      return {
        ...state,
        toastInformation: {
          ...state.toastInformation,
          isVisible: false,
          title: "",
          description: "",
          watchURL: "",
        },
      };
    default:
      return state;
  }
};

export const AppProvider = ({ children }) => {
  const [appState, appDispatch] = useReducer(configReducer, initialState);
  const appData = { appState, appDispatch };
  return <AppContext.Provider value={appData}>{children}</AppContext.Provider>;
};
