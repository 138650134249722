import React, { useEffect, useState } from "react";
import InfoIcon from "../atoms/icons/InfoIcon";
import StoryCard from "../atoms/StoryCard";
import { TOOLTIP_MESSAGES } from "../constants/constants";
import { getStoriesOfPlotCard } from "../services/story";

const PlotCardDetailsModal = ({
  selectedPlotCard,
  setSelectedPlotCard,
  setCurrentStep,
}) => {
  const [stories, setStories] = useState({ loading: false, data: [] });
  useEffect(() => {
    const getStoriesList = async () => {
      const storiesResponse = await getStoriesOfPlotCard(
        selectedPlotCard?.plotCardId,
        3
      );
      console.log("stories response", storiesResponse);
      setStories({ loading: false, data: storiesResponse });
    };
    console.log(selectedPlotCard);
    setStories({ loading: true, data: [] });
    getStoriesList();
  }, [selectedPlotCard]);
  const showModal = !!selectedPlotCard;
  return (
    <div>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div
              className="relative w-auto my-6 mx-auto"
              style={{ maxWidth: "90%" }}
            >
              {/*content*/}
              <div
                className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
                style={{
                  backgroundColor: "#f2f2f2",
                  minHeight: "65vh",
                  minWidth: "60vw",
                  maxHeight: "90vh",
                }}
              >
                {/*header*/}
                <div className="flex items-start justify-between p-5 rounded-t">
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setSelectedPlotCard(false)}
                  >
                    <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none opacity-90">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto overflow-auto max-h-full">
                  <div className="flex w-full h-full">
                    <div
                      className="flex-1 h-full py-12"
                      style={{ height: "45vh", width: "35vw" }}
                    >
                      <img
                        src={selectedPlotCard?.thumbnail}
                        alt="test"
                        className="object-contain h-full w-full"
                      />
                    </div>
                    <div className="flex-1 p-4">
                      <div>
                        <h1 className="text-3xl text-primary-400 font-bold py-6">
                          {selectedPlotCard?.name}
                        </h1>
                        <p>{selectedPlotCard?.description}</p>
                        <br />
                        <span className=" text-gray-500 my-4">
                          <a className=" font-bold pr-2 text-gray-800">
                            Category:
                          </a>
                          {selectedPlotCard?.categories?.join(", ")}
                        </span>
                        <br />
                        <br />

                        <span className=" text-gray-500 my-4">
                          <a className=" font-bold pr-2 text-gray-800">
                            Best for:
                          </a>
                          {selectedPlotCard?.categories?.join(", ")}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="p-4">
                  <div className="flex text-gray-500 text-2xl text-center pt-4 font-thin pb-2">
                      Know
                      <div className="pt-1 pl-2 has-tooltip">
                        <InfoIcon />
                        <span className="tooltip font-medium">
                          {TOOLTIP_MESSAGES.PLOTCARD_DETAILS.DEFINE}
                        </span>
                      </div>
                    </div>
                    <span className="text-primary-500 text-3xl font-semibold pt-2">
                      Stories in this plotcard
                    </span>
                  </div>
                  <div
                    className={`ml-4 grid gap-2 max-h-screen overflow-y-auto ${
                      stories?.data?.length >= 3
                        ? "grid-cols-3 2xl:grid-cols-4"
                        : "grid-cols-2"
                    }`}
                  >
                    {!stories?.data?.length && "No results found!"}
                    {(stories?.data ?? []).map((story) => (
                      <StoryCard
                        key={story?.id}
                        story={story}
                        showReadMore={false}
                        setCurrentStep={setCurrentStep}
                        showActions
                        isMainPage
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  );
};

export default PlotCardDetailsModal;
