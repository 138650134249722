import { React, Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
// import { BellIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import NotificationIcon from "../assets/KathaiIcons/notification_grey.png";
import NotificationIconHover from "../assets/KathaiIcons/notification_pink.png";
import UserIcon from "../assets/KathaiIcons/user_grey.png";
import UserIconHover from "../assets/KathaiIcons/user_pink.png";
import { signOut } from "../auth";

// const profile = ['Your Profile', 'Settings', 'Sign out']

const profile = {
  yourProfile: "Your Profile",
  settings: "Settings",
  signout: "Sign out",
};

export default function Profile(props) {
  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };

  const onMenuItem = (item) => async (e) => {
    e.preventDefault();
    // console.log("onMenu", item);
    if (profile[item] === profile.signout) {
      try {
        await signOut();
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <Fragment>
      <div className="hidden md:block">
        <div className="ml-4 flex items-center md:ml-6 mr-6">
          <button className="p-1 rounded-full text-gray-400 hover:text-white">
            <span className="sr-only">View notifications</span>
            <img
              className="h-10 w-10"
              src={NotificationIcon}
              onMouseOver={(e) => (e.currentTarget.src = NotificationIconHover)}
              onMouseOut={(e) => (e.currentTarget.src = NotificationIcon)}
            ></img>
          </button>

          {/* Profile dropdown */}
          <Menu as="div" className="ml-3 relative">
            {({ open }) => (
              <>
                <div>
                  <Menu.Button className="max-w-xs  rounded-full flex items-center text-sm">
                    <span className="sr-only">Open user menu</span>
                    <img
                      className="h-8 w-8 rounded-full"
                      src={
                        props.userData && props.userData.profilepicture
                          ? props.userData.profilepicture
                          : UserIcon
                      }
                      onMouseOver={(e) =>
                        (e.currentTarget.src =
                          props.userData && props.userData.profilepicture
                            ? props.userData.profilepicture
                            : UserIconHover)
                      }
                      onMouseOut={(e) =>
                        (e.currentTarget.src =
                          props.userData && props.userData.profilepicture
                            ? props.userData.profilepicture
                            : UserIcon)
                      }
                      alt=""
                    />
                  </Menu.Button>
                </div>
                <Transition
                  show={open}
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    static
                    className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white "
                    style={{ zIndex: 2 }}
                  >
                    {Object.keys(profile).map((item) => (
                      <Menu.Item key={item}>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                            onClick={onMenuItem(item)}
                          >
                            {profile[item]}
                          </a>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </div>
      </div>
    </Fragment>
  );
}
