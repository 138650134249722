import React from "react";

const Input = ({
  type = "password",
  className = "w-5/6 p-2 border-b border-black",
  placeholder = "password",
  ...rest
}) => {
  return (
    <input {...{type,className,placeholder}} {...rest}
    />
  );
};

export default Input;
