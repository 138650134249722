import firebase from "firebase/app";

const collections = {
  mailer: "mailer",
  audience: "audience",
  objective: "objective",
};

const collectionName = "suggestions";

export const getSuggestions = async () => {
  const querySnapshot = await firebase
    .firestore()
    .collection("autosuggest")
    .get();

  const suggestions = querySnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));

  return suggestions;
};
