import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import Alert from "../atoms/Alert";
import { resetPasswordEmail } from "../auth";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [hasEmailSent, setHasEmailSent] = useState(false);

  const onEmailChange = useCallback(
    (e) => {
      setEmail(e.target.value);
      if (error) setError("");
      if (hasEmailSent) setHasEmailSent(false);
    },
    [error]
  );

  const onPasswordReset = useCallback(async () => {
    // console.log("onPassword reset", email);
    if (email !== "") {
      try {
        await resetPasswordEmail(email);
        setHasEmailSent(true);
      } catch (e) {
        console.error(e);
        setError(e.message);
      }
    }
  }, [email]);

  return (
    <div>
      <div className="container mx-auto">
        <div className="flex justify-center px-6 my-12">
          <div className="w-full xl:w-3/4 lg:w-11/12 flex">
            {/* <div
              className="w-full h-auto bg-gray-400 hidden lg:block lg:w-1/2 bg-cover rounded-l-lg"
            //   style="background-image: url('https://source.unsplash.com/oWTW-jNGl9I/600x800')"
            ></div> */}

            <div className="w-full lg:w-1/2 bg-white p-5 mx-auto rounded-lg lg:rounded-l-none">
              <div className="px-8 mb-4 text-center">
                <h3 className="pt-4 mb-2 text-2xl">Forgot Your Password?</h3>
                <p className="mb-4 text-sm text-gray-700">
                  We get it, stuff happens. Just enter your email address below
                  and we'll send you a link to reset your password!
                </p>
              </div>
              <form className="px-8 pt-6 pb-8 mb-4 bg-white rounded">
                <div className="mb-4">
                  <label
                    className="block mb-2 text-sm font-bold text-gray-700"
                    htmlFor="email"
                  >
                    Email
                  </label>
                  <input
                    className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                    id="email"
                    type="email"
                    placeholder="Enter Email Address..."
                    onChange={onEmailChange}
                  />
                </div>
                <div className="mb-6 text-center">
                  <button
                    className="w-full px-4 py-2 font-bold text-white bg-red-500 rounded-md hover:bg-red-700 focus:outline-none focus:shadow-outline"
                    type="button"
                    onClick={onPasswordReset}
                  >
                    Reset Password
                  </button>
                </div>
                <hr className="mb-6 border-t" />
                <div className="text-center">
                  <Link
                    className="inline-block text-sm text-blue-500 align-baseline hover:text-blue-800"
                    to="/signup"
                  >
                    Create an Account!
                  </Link>
                </div>
                <div className="text-center">
                  <Link
                    className="inline-block text-sm text-blue-500 align-baseline hover:text-blue-800"
                    to="/signin"
                  >
                    Already have an account? Login!
                  </Link>
                </div>
              </form>
              {error && <Alert error message={error} />}
              {hasEmailSent && (
                <Alert
                  success
                  message={"Password reset email sent successfully"}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
