import React from 'react';
import ReactDOM from 'react-dom';
import firebase  from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


// Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  var firebaseConfig = {
    apiKey: "AIzaSyAe6dGZTFq_JwVa3TxqNslfayMA1G3PYNg",
    authDomain: "kathai-6af04.firebaseapp.com",
    projectId: "kathai-6af04",
    storageBucket: "kathai-6af04.appspot.com",
    messagingSenderId: "649893834603",
    appId: "1:649893834603:web:6f968826f4ebd3ec6f662e",
    measurementId: "G-K4T2Y5ZSDC"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
