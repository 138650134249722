import React, { useState } from "react";
import Autosuggest from "react-autosuggest";

import './style.css';

const AutoSuggestInput = ({ data, value, setValue, topText, exampleText }) => {
  const [suggestions, setSuggestions] = useState([]);

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : data.filter(
          (item) => item.toLowerCase().slice(0, inputLength) === inputValue
        );
  };

  const getSuggestionValue = (suggestion) => suggestion;

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onChange = (event, { newValue }) => {
    setValue(newValue);
  };

  const renderSuggestion = (suggestion) => (
    <div className="p-2 bg-gray-50 hover:bg-gray-100 text-left cursor-pointer">
      <span>{suggestion}</span>
    </div>
  );

  return (
    <div
      className="c-autosuggest bg-primarybg text-gray-800 border-b-2 border-black w-24 mx-4 my-2 flex-grow"
      style={value ? {} : { fontSize: "1rem" }}
    >
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={{
          placeholder: topText + exampleText,
          value,
          onChange,
        }}
      />
    </div>
  );
};

export default AutoSuggestInput;
