import firebase from "firebase/app";

const collections = {
  TagIndexStories: "tagindexstories",
  userStories: "userstories",
  templates: "templates",
};

/**
 * Get all stories
 * @returns array of storyDoc
 */

export const getStories = async () => {
  const querySnapshot = await firebase.firestore().collection("stories").get();

  const stories = querySnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));

  return stories;
};

export const getStoryById = async (storyId) => {
  const plotcardDoc = await firebase
    .firestore()
    .collection("plotcards")
    .doc(storyId)
    .get();

  return plotcardDoc;
};

/**
 * Get All stories with pagination
 */
export const getStoriesWithlimit = async (limit = 25, lastDoc = null) => {
  const first = firebase.firestore().collection("stories").limit(limit);

  let storiesSnapshot = [];

  if (lastDoc === null) {
    storiesSnapshot = await first.get();
  } else {
    const next = firebase
      .firestore()
      .collection("stories")
      .startAfter(lastDoc)
      .limit(limit);

    storiesSnapshot = await next.get();
  }

  const stories = storiesSnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));

  return stories;
};

/**
 * tagsName - [] max 10 as per firestore limit.
 * plotCardId - plotcard Id of selected plotcard
 * @returns plotcardId's
 */
export const getStoriesByTags = async (tagNames = [], plotcardId) => {
  // console.log(tagNames, plotcardId);
  if (plotcardId) {
    const querySnapshot = await firebase
      .firestore()
      .collection("stories")
      .where("plotcardId", "==", plotcardId)
      // .where("tagName", "in", tagNames)
      .get();

    const stories = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    return stories;
  }
};

export const getStoriesOfPlotCard = async (plotcardId, limit = 10) => {
  if (plotcardId) {
    const querySnapshot = await firebase
      .firestore()
      .collection("stories")
      .where("plotcardId", "==", plotcardId)
      .limit(limit)
      .get();

    const stories = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    return stories;
  }
};

/**
 * Create private user story
 * @param {Object} storyObj {
 *                  author : <uid>,
 *                  template_uuid: <UUID>,
 *                  isPublic : false,
 *                  designBlobUrl: <url>,
 *                  thumbnailBlobUrl: <url>
 *                 }
 *
 * @returns storyDocId
 */

export const createUserStory = async (storyObj = {}) => {
  const userStoriesRef = firebase
    .firestore()
    .collection(collections.userStories);
  const documentData = {
    ...storyObj,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
  };
  const docSnapshot = await userStoriesRef.add(documentData);
  return docSnapshot.id;
};

/**
 * Create public template.
 * @param {*} storyObj {
 *                  author : <uid>,
 *                  isPublic : true,
 *                  designBlobUrl: <url>,
 *                  thumbnailBlobUrl: <url>
 *                 }
 * @returns templateId
 */
export const createTemplate = async (templateObj = {}) => {
  const templateRef = firebase.firestore().collection(collections.templates);
  const documentData = {
    ...templateObj,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
  };
  const docSnapshot = await templateRef.add(documentData);
  return docSnapshot.id;
};
/**
 * Update template with templateId
 * @param {*} templateId
 * @param {*} templateObj
 */
export const updateTemplate = async (templateId, templateObj) => {
  if (templateId) {
    const storyRef = firebase
      .firestore()
      .collection(collections.templates)
      .doc(templateId);
    await storyRef.update({
      ...templateObj,
    });
  }
};

/**
 * Update user story
 */
export const updateUserStory = async (storyId, storyObj = {}) => {
  if (storyId) {
    const storyRef = firebase
      .firestore()
      .collection(collections.userStories)
      .doc(storyId);
    await storyRef.update({
      ...storyObj,
    });
  }
};

/**
 * Get all user private stories by uid
 * @param {String} uid
 * @returns storiesCollection
 */

export const getAllUserStories = async (uid) => {
  const querySnapshot = await firebase
    .firestore()
    .collection(collections.userStories)
    .where("author", "==", uid)
    .get();

  const userStories = querySnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));

  return userStories;
};

/**
 * Get all user private stories by uid
 * @param {String} storyId
 * @returns storiesCollection
 */

export const getUserStory = async (storyId) => {
  const docSnapshot = await firebase
    .firestore()
    .collection(collections.userStories)
    .doc(storyId)
    .get();

  const storyDoc = docSnapshot.data();

  return {
    ...storyDoc,
    id: docSnapshot.id,
  };
};

/**
 * Get User's recent stories.
 */
export const getRecentStories = async (userId, limit = 25, lastDoc = null) => {
  if (userId) {
    const query = firebase
      .firestore()
      .collection(collections.userStories)
      .where("author", "==", userId)
      .orderBy("modifiedAt","desc")

    const first = query.limit(limit);

    let storiesSnapshot = [];

    if (lastDoc === null) {
      storiesSnapshot = await first.get();
    } else {
      const next = query.startAfter(lastDoc).limit(limit);

      storiesSnapshot = await next.get();
    }

    const stories = storiesSnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    return stories;
  }
};
